import RoleType from '../../constants/roleType';
import RequestV2 from './request-v2';

export default class RoleHttpService {
    private static uri = 'role';

    public static readMany(params: { roleType: RoleType }) {
        return RequestV2.get(this.uri, params);
    }
}
