import React, { useState, createContext, useContext, useEffect } from 'react';
import ContactHttpService from '../services/http/contact-http';
import { AuthValues, useAuth } from './authContext';

const ContactContext = createContext<any>({});
export const useContact = () => useContext(ContactContext);

export function ConsultantContactProvider(props: any) {
    const { user }: AuthValues = useAuth();

    const [consultants, setConsultants] = useState<any[]>([]);

    async function loadContacts() {
        const { data } = await ContactHttpService.readMany({});

        const consultantByState = [];

        if (!data) return;

        // eslint-disable-next-line no-restricted-syntax
        for (const contact of data.data) {
            const states = contact.states.split(',');

            // eslint-disable-next-line no-restricted-syntax
            for (const state of states) {
                consultantByState.push({
                    state,
                    consultantName: contact.consultantName,
                    rdStationId: contact.rdStationId,
                    consultantWhatsapp: `55${contact.phoneNumber}`,
                });
            }
        }

        setConsultants(consultantByState);
    }

    useEffect(() => {
        if (user) {
            loadContacts();
        }
    }, [user]);

    return (
        <ContactContext.Provider
            value={{
                consultants,
                setConsultants,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}
