import { IBrasilApiCEP } from '../../shared/interfaces/brasil-cep-api.interface';
import { IBrasilApiStates } from '../../shared/interfaces/brasil-states-api.interface';
import { IBrasilAPICities } from '../../shared/interfaces/brasil-cities-api.interface';
import RequestV2 from './request-v2';

export default class BrasilService {
    private static uri = 'brazil';

    public static async getDataByCep(cep: string): Promise<IBrasilApiCEP> {
        try {
            const request = await RequestV2.get(`${this.uri}/cep/${cep}`);
            return request.data;
        } catch (error) {
            throw new Error('CEP não encontrado');
        }
    }

    public static async getStates(): Promise<IBrasilApiStates[]> {
        try {
            const request = await RequestV2.get(`${this.uri}/state`);
            return request.data;
        } catch (error) {
            throw new Error(
                'Não foi possível encontrar os estados brasileiros',
            );
        }
    }

    public static async getCitiesByState(
        uf: string,
    ): Promise<IBrasilAPICities[]> {
        try {
            const request = await RequestV2.get(`${this.uri}/state/${uf}/city`);
            return request.data;
        } catch (error) {
            throw new Error(
                'Não foi possível encontrar as cidades brasileiras',
            );
        }
    }
}
