import React, { useRef, useState } from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    Col,
    Dropdown,
    Form,
    Image,
    InputGroup,
    Row,
    Spinner,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import filterIcon from '../../assets/icons/filter.png';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import BaseLayout from '../../components/BaseLayout';
import NotificationIcon from '../../constants/notificationIcon';
import { rowsPerPage } from '../../constants/pagination';
import {
    contributorCreateRoute,
    contributorEditRoute,
} from '../../routes/config';
import RoleHttpService from '../../services/http/role-http';
import UserHttpService from '../../services/http/user-http';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { StyledTh } from './styles';
import Contribuitor from '../../shared/interfaces/contribuitor.interface';
import { UserFilterProps } from '../../components/User/UserFilter';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../styles/react-select-config';
import { StyledTable } from '../Users/styles';
import RoleType from '../../constants/roleType';

const List: React.FC = () => {
    const [filterToggle, setFilterToggle] = useState(false);

    const [selectedContribuitorId, setSelectedContributorId] = useState<
        number | null
    >(null);
    const [showRemoveContribuitorModal, setShowRemoveContribuitorModal] =
        useState<boolean>(false);

    const { control, watch } = useForm<UserFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
            filterRoles: [],
        },
    });

    const filterData = watch();
    const termTrimmed = filterData.filterTerm.trim();

    const loadRoles = async () => {
        const response = await RoleHttpService.readMany({
            roleType: RoleType.ManagerOrContributorRoles,
        });

        return response.data.data.map((role: any) => ({
            value: role.id,
            label: role.name,
            reference: role.reference,
        }));
    };

    const rolesQuery = useQuery('roles', loadRoles, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    async function loadUsers({ pageParam = 0 }): Promise<{
        data: Contribuitor[];
        currentPage: number;
        pages: number;
    }> {
        const params = {
            term: termTrimmed || '',
            skip: pageParam,
            take: rowsPerPage,
            roles:
                filterData?.filterRoles?.map((item) => item.value).join(',') ||
                '',
            roleType: RoleType.ManagerOrContributorRoles,
        };

        const response = await UserHttpService.readMany(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.total / rowsPerPage),
        };
    }

    const contribuitorsQuery = useInfiniteQuery(
        ['contribuitors', termTrimmed, filterData.filterRoles],
        loadUsers,
        {
            getNextPageParam: (response) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }

                return null;
            },
            staleTime: 300000,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
        },
    );

    const loadMoreRef = useRef(null);

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: contribuitorsQuery.fetchNextPage,
        enabled: contribuitorsQuery.hasNextPage,
    });

    const showLoading =
        contribuitorsQuery.isLoading ||
        contribuitorsQuery.isFetchingNextPage ||
        !contribuitorsQuery.isFetched;

    const deleteUser = async () => {
        try {
            if (!selectedContribuitorId) {
                throw new Error('Usuário não encontrado para exclusão');
            }
            await UserHttpService.delete(selectedContribuitorId);

            toast.success('Usuário removido com sucesso');

            contribuitorsQuery.refetch();
        } catch (err) {
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao remover o usuário.');
        }
    };

    const contribuitors = contribuitorsQuery.data?.pages.reduce(
        (accumulator, current) => accumulator.concat(current.data),
        [] as Contribuitor[],
    );

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Colaboradores
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Todas as informações dos colaboradores em um só lugar.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <ButtonGroup className="float-right">
                        <Button
                            className="mr-1"
                            variant="light"
                            style={{
                                backgroundColor: '#EEEEEE',
                            }}
                            onClick={() => setFilterToggle(!filterToggle)}
                        >
                            <Image src={filterIcon} />
                        </Button>

                        <Link to={contributorCreateRoute.path}>
                            <Button className="float-right">
                                <i className="fas fa-plus" /> Novo Colaborador
                            </Button>
                        </Link>
                    </ButtonGroup>
                </Col>
            </Row>

            {filterToggle && (
                <Card className="mt-4 mx-2">
                    <Card.Body>
                        <Card.Title>
                            <strong>Filtros</strong>
                        </Card.Title>

                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formBasicRole">
                                    <Form.Label>Permissão</Form.Label>
                                    <Controller
                                        isClearable
                                        className="form-control-nexen"
                                        as={
                                            <Select
                                                isDisabled={
                                                    rolesQuery.isLoading
                                                }
                                            />
                                        }
                                        control={control}
                                        name="filterRoles"
                                        options={rolesQuery.data}
                                        isMulti
                                        styles={{
                                            control: tradeControl,
                                            option: optionsTheme,
                                        }}
                                        theme={theme}
                                        placeholder={
                                            rolesQuery.isLoading
                                                ? 'Carregando permissões...'
                                                : 'Selecione...'
                                        }
                                        defaultValue=""
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}

            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                            as={
                                <Form.Control placeholder="Digite aqui o que procura..." />
                            }
                            type="text"
                            name="filterTerm"
                            control={control}
                            defaultValue=""
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="pl-2 pr-2">
                <Col>
                    <StyledTable
                        bordered
                        hover
                        size="sm"
                        className="text-center"
                    >
                        <thead>
                            <tr>
                                <StyledTh>NOME</StyledTh>
                                <StyledTh>INTEGRADOR</StyledTh>
                                <StyledTh>EMAIL</StyledTh>
                                <StyledTh>PERMISSÕES</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {contribuitors?.map((contribuitor) => (
                                <tr key={contribuitor.id}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={contribuitor.name}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={
                                                contribuitor.owner?.name
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={contribuitor.email}
                                        />
                                    </td>
                                    <td>
                                        <h5>
                                            {contribuitor?.roles.map((role) => (
                                                <Badge
                                                    style={{
                                                        margin: '1%',
                                                        backgroundColor:
                                                            'rgba(47, 204, 139, 0.1)',
                                                        color: ' #2fcc8b',
                                                    }}
                                                    variant="light"
                                                    key={role.id}
                                                >
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimmed,
                                                        ]}
                                                        textToHighlight={
                                                            role.name
                                                        }
                                                    />
                                                </Badge>
                                            ))}
                                        </h5>
                                    </td>

                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to={contributorEditRoute.build(
                                                        contribuitor,
                                                    )}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedContributorId(
                                                            contribuitor.id,
                                                        );
                                                        setShowRemoveContribuitorModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </StyledTable>
                </Col>
            </Row>
            <Col md={12} className="text-center" ref={loadMoreRef}>
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}
                {!showLoading && !contribuitors?.length && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Col>
            {showRemoveContribuitorModal && (
                <ConfirmationDialog
                    show={showRemoveContribuitorModal}
                    onHide={() => {
                        setShowRemoveContribuitorModal(false);
                        setSelectedContributorId(null);
                    }}
                    onConfirm={deleteUser}
                    icon={NotificationIcon.Warning}
                    title="Excluir colaborador"
                    text="Deseja excluir o colaborador selecionado?"
                />
            )}
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { List };
