/* eslint-disable import/prefer-default-export */
import { useRef } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Image,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ReactComponent as EraserIcon } from '../../assets/icons/eraser-solid.svg';
import filterIcon from '../../assets/icons/filter.png';
import BaseLayout from '../../components/BaseLayout';
import IntegratorDigitalContractModal from '../../components/Integrator/IntegratorDigitalContractModal';
import IntegratorFilter from '../../components/Integrator/SellerFilter';
import NotificationIcon from '../../constants/notificationIcon';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import selectOptionValuesAsString from '../../utils/selectOptionValuesAsString';
import { StyledTh } from './styles';
import useSellers from '../../hooks/useSellers';
import SellerTableRow from '../../components/Integrator/SellerTableRow';
import Seller from '../../shared/interfaces/seller.interface';
import sellerStatus from '../../constants/sellerStatus';
import InternalNotesModal from '../../components/Integrator/SellerInternalNotesModal';
import IntegratorHttpService from '../../services/http/integrator-http';

export function List() {
    const rowsPerPage = 25;

    const {
        showBlockIntegratorModal,
        setShowBlockIntegratorModal,
        showUnblockIntegratorModal,
        setShowUnblockIntegratorModal,
        showDigitalContractModal,
        setShowDigitalContractModal,
        showFilterSection,
        setShowFilterSection,
        setSellerOnDigitalContractUpdate,
        selectedSeller,
        setSelectedSeller,
        filterForm,
        showInternalNotesModal,
    } = useSellers();

    const filterData = filterForm.watch();

    async function getSellers({
        pageParam = 0,
    }: {
        pageParam?: number;
    }): Promise<{ data: Seller[]; currentPage: number; pages: number }> {
        const {
            filterTerm,
            filterAttachment,
            filterStatus,
            filterContract,
            filterComercialConsultants,
            filterCities,
            filterStates,
            filterStartDate,
            filterEndDate,
        } = filterData;

        const params = {
            term: filterTerm || '',
            skip: pageParam,
            take: rowsPerPage,
            ...(filterAttachment && {
                attachmentFilter: filterAttachment.value,
            }),
            ...(filterStatus?.length && {
                statusFilter: selectOptionValuesAsString(filterStatus),
            }),
            ...(filterContract?.length && {
                contractFilter: selectOptionValuesAsString(filterContract),
            }),
            ...(filterComercialConsultants?.length && {
                commercialConsultantsFilter: selectOptionValuesAsString(
                    filterComercialConsultants,
                ),
            }),
            ...(filterCities?.length && {
                citiesFilter: selectOptionValuesAsString(filterCities),
            }),
            ...(filterStates?.length && {
                statesFilter: selectOptionValuesAsString(filterStates),
            }),
            ...(filterStartDate && { startDateFilter: filterStartDate }),
            ...(filterEndDate && { endDateFilter: filterEndDate }),
        };

        const response = await IntegratorHttpService.readMany(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.total / rowsPerPage),
        };
    }

    const sellersQuery = useInfiniteQuery({
        queryKey: [
            'sellers',
            filterData.filterTerm,
            filterData.filterStatus,
            filterData.filterAttachment,
            filterData.filterContract,
            filterData.filterComercialConsultants,
            filterData.filterStartDate,
            filterData.filterEndDate,
            filterData.filterStates,
            filterData.filterCities,
        ],
        queryFn: getSellers,
        getNextPageParam: ({ currentPage, pages }) =>
            currentPage + 1 < pages ? currentPage + 1 : null,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const blockSellerMutation = useMutation({
        mutationFn: async (observation: string) => {
            if (!selectedSeller) {
                throw new Error('Integrador não encontrado para bloqueio');
            }

            await IntegratorHttpService.updateStatus(
                selectedSeller.id,
                sellerStatus.Disabled,
                observation,
            );
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao bloquear o integrador');
        },
        onSuccess: () => {
            toast.success('Integrador bloqueado com sucesso');
            sellersQuery.refetch();
        },
    });

    const unblockSellerMutation = useMutation({
        mutationFn: async () => {
            if (!selectedSeller) {
                throw new Error('Integrador não encontrado para desbloqueio');
            }

            await IntegratorHttpService.updateStatus(
                selectedSeller.id,
                sellerStatus.Approved,
                '',
            );
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao desbloquear o integrador');
        },
        onSuccess: () => {
            toast.success('Integrador desbloqueado com sucesso');
            sellersQuery.refetch();
        },
    });

    const updateDigitalContractMutation = useMutation({
        mutationFn: async (sellerId: number) => {
            setSellerOnDigitalContractUpdate(sellerId);
            await IntegratorHttpService.updateClicksignDocument(
                sellerId.toString(),
            );
        },
        onSuccess: () => {
            toast.success('Busca realizada com sucesso');
            sellersQuery.refetch();
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao realizar a busca do contrato assinado');
        },
        onSettled: () => {
            setSellerOnDigitalContractUpdate(null);
        },
    });

    const sellers = sellersQuery.data?.pages.flatMap((page) =>
        page.data.map((item) => item),
    );

    const target = useRef(null);

    useIntersectionObserver({
        target,
        onIntersect: sellersQuery.fetchNextPage,
        enabled: sellersQuery.hasNextPage,
    });

    const showLoading =
        sellersQuery.isLoading ||
        sellersQuery.isFetchingNextPage ||
        !sellersQuery.isFetched;

    return (
        <BaseLayout>
            <Row className="header align-items-center justify-content-between pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Integradores
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Todas as informações dos integradores em um só lugar.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <ButtonGroup>
                        {filterForm.formState.isDirty && (
                            <Button
                                style={{
                                    color: '#2F80ED',
                                }}
                                className="mr-2"
                                variant="text"
                                onClick={() => filterForm.reset()}
                            >
                                <EraserIcon
                                    fill="#2F80ED"
                                    className="mr-2"
                                    width={18}
                                />{' '}
                                Limpar filtros
                            </Button>
                        )}
                        <Button
                            className="mr-1"
                            variant="light"
                            style={{ backgroundColor: '#EEEEEE' }}
                            onClick={() =>
                                setShowFilterSection(!showFilterSection)
                            }
                        >
                            <Image src={filterIcon} />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <IntegratorFilter />
            <Row className="pl-2 pr-2">
                <Col>
                    <Table
                        bordered
                        hover
                        size="sm"
                        className="text-center"
                        style={{ maxWidth: '100%', minWidth: '100%' }}
                    >
                        <thead>
                            <tr>
                                <StyledTh>ID</StyledTh>
                                <StyledTh>STATUS</StyledTh>
                                <StyledTh>CONTRATO</StyledTh>
                                <StyledTh>ANEXOS</StyledTh>
                                <StyledTh>CNPJ</StyledTh>
                                <StyledTh>NOME</StyledTh>
                                <StyledTh>E-MAIL</StyledTh>
                                <StyledTh>CIDADE/UF</StyledTh>
                                <StyledTh>CADASTRO EM</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {sellers?.map((seller) => (
                                <SellerTableRow
                                    seller={seller}
                                    key={seller.id}
                                    onUpdateDigitalContract={
                                        updateDigitalContractMutation.mutateAsync
                                    }
                                />
                            ))}
                        </tbody>
                    </Table>
                </Col>

                <Col md={12} className="text-center" ref={target}>
                    {showLoading && (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    )}

                    {!showLoading && !sellers?.length && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>

            {showDigitalContractModal && selectedSeller && (
                <IntegratorDigitalContractModal
                    id={selectedSeller.id.toString()}
                    show
                    onHide={() => {
                        setSelectedSeller(null);
                        setShowDigitalContractModal(false);
                    }}
                    reset={sellersQuery.refetch}
                />
            )}

            {showBlockIntegratorModal && (
                <ConfirmationDialog
                    show={showBlockIntegratorModal}
                    onHide={() => {
                        setShowBlockIntegratorModal(false);
                        setSelectedSeller(null);
                    }}
                    onConfirmWithFeedback={blockSellerMutation.mutateAsync}
                    icon={NotificationIcon.Warning}
                    title="Bloquear integrador"
                    text="Você deseja bloquear o integrador selecionado?"
                    placeholder="Insira o motivo do bloqueio..."
                    required
                />
            )}

            {showUnblockIntegratorModal && (
                <ConfirmationDialog
                    show={showUnblockIntegratorModal}
                    onHide={() => {
                        setShowUnblockIntegratorModal(false);
                        setSelectedSeller(null);
                    }}
                    onConfirm={unblockSellerMutation.mutateAsync}
                    icon={NotificationIcon.Info}
                    title="Desbloquear integrador"
                    text="Você deseja desbloquear o integrador selecionado?"
                />
            )}

            {showInternalNotesModal && <InternalNotesModal />}
        </BaseLayout>
    );
}
