import React, { useState } from 'react';
import { Col, Row, Form, Image, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import AccessLayout from '../../components/AccessLayout';
import HeaderAccess from '../../components/HeaderAccess';
import getValidationsErrors from '../../utils/getValidationsErrors';
import {
    integratorRules,
    addressRules,
    companyRules,
    userRulesIntegrator,
    partnerRules,
    companyInfoRules,
} from '../../validations/integrator';
import isValidationError from '../../utils/isValidationError';
import history from '../../services/history';
import { signInRoute } from '../../routes/config/signin.route';
import IntegratorAddress from '../../components/Integrator/IntegratorAddress';
import IntegratorCompany from '../../components/Integrator/IntegratorCompany';
import IntegratorUser from '../../components/Integrator/IntegratorUser';
import { StyledButton } from '../SignIn/styles';
import UserTerms from '../../components/UserTerms/TermsModal';
import smallWhiteRightArrow from '../../assets/icons/small_white_right_arrow.svg';
import { StepProgressBar } from './ProgressBar';
import {
    HeaderTitle,
    HeaderTitleSuccess,
    StyledCol,
    StyledImage,
    StyledSpan,
} from './styles';
import IntegratorCompanyInfo from '../../components/Integrator/IntegratorCompanyInfo';
import IntegratorPartner from '../../components/Integrator/IntegratorPartner';
import heart from '../../assets/icons/heart.svg';
import nexenLogo from '../../assets/nexen.svg';
import handleResponseError from '../../utils/handleResponseError';
import IntegratorHttpService from '../../services/http/integrator-http';

const SignUp: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const newIntegrator = () => ({
        id: '',
        userid: '',
        integratorsNick: '',
        name: '',
        email: '',
        cnpj: '',
        phone: '',
        profilePoints: 0,
        profile: 'Ainda não definido',
        ie: '',
        hasIe: '',
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: '',
        erpCity: '',
        erpCityName: '',
        fancyName: '',
        password: '',
        password_confirmation: '',
        partnerName: '',
        partnerDocument: '',
        partnerBirthDate: '',
        partnerEmail: '',
        partnerPhone: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
    });

    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState(newIntegrator());
    const [integrator, setIntegrator] = useState(newIntegrator());
    const [isLoading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const clearErrors = () => setErrors(newIntegrator());

    const goToSecondStep = async () => {
        const schema = Yup.object().shape(userRulesIntegrator);

        clearErrors();

        try {
            await schema.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });

            setStep(2);
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
        }
    };

    const goToThirdStep = async () => {
        const schema = Yup.object().shape(partnerRules);

        clearErrors();

        try {
            await schema.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
            setStep(3);
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
        }
    };

    const goToFourthStep = async () => {
        const schema = Yup.object().shape(companyRules);

        clearErrors();

        try {
            await schema.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
            setStep(4);
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
        }
    };

    const goToFifthStep = async () => {
        const schema = Yup.object().shape(addressRules);

        clearErrors();

        try {
            await schema.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
            setStep(5);
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
        }
    };

    const handleChanges = async (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        const rules: any = {};

        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            changes[item.target.name] =
                item.target.name === 'ie'
                    ? item.target.value?.replace(/\D/g, '')
                    : item.target.value;
            newErrors[item.target.name] = undefined;

            rules[item.target.name] = integratorRules[item.target.name];
        });

        const updatedIntegrator = { ...integrator, ...changes };

        setIntegrator(updatedIntegrator);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        clearErrors();
        setLoading(true);

        const schema = Yup.object().shape(companyInfoRules);

        try {
            await schema.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });

            await IntegratorHttpService.create({
                name: integrator.name,
                cnpj: integrator.cnpj,
                phone: integrator.phone,
                ie: integrator.hasIe === '1' ? integrator.ie : 'ISENTO',
                addressState: (integrator.erpState as any).value,
                addressCity: (integrator.erpCity as any).label,
                addressCep: integrator.addressCep.toString().replace(/\D/g, ''),
                addressNumber: integrator.addressNumber,
                addressDescription: integrator.addressDescription,
                addressComplement: integrator.addressComplement,
                addressNeighborhood: integrator.addressNeighborhood,
                fancyName: integrator.fancyName,
                user: {
                    email: integrator.email,
                    name: integrator.integratorsNick,
                    password: integrator.password,
                },
                companyInfo: {
                    partnerName: integrator.partnerName,
                    partnerDocument: integrator.partnerDocument,
                    partnerBirthDate: integrator.partnerBirthDate,
                    partnerEmail: integrator.partnerEmail,
                    partnerPhone: integrator.partnerPhone,
                    question1: integrator.question1,
                    question2: integrator.question2,
                    question3: integrator.question3,
                    question4: integrator.question4,
                    question5: integrator.question5,
                    question6: integrator.question6,
                },
            });

            setTimeout(() => {
                setShowSuccessMessage(true);
            }, 5000);
        } catch (error) {
            const err = error as any;

            if (isValidationError(err)) {
                setErrors(getValidationsErrors(err) as any);
            } else {
                handleResponseError(
                    error,
                    'Erro ao alterar cadastro do integrador',
                );
            }

            setLoading(false);
        }
    };

    const renderExtraContent = () => {
        if (showSuccessMessage) {
            return (
                <Row>
                    <Col md={1} />
                    <StyledCol md={10} className="mb-4">
                        <StyledImage className="mb-4" src={nexenLogo} />
                        <HeaderTitleSuccess>
                            Cadastro realizado com sucesso!
                        </HeaderTitleSuccess>
                        <div
                            style={{
                                padding: '20px 50px',
                                textAlign: 'center',
                            }}
                        >
                            <h6
                                style={{ fontWeight: 'bold', marginBottom: 30 }}
                            >
                                Prezado integrador, informamos que o seu
                                cadastro foi realizado com sucesso.
                            </h6>
                            <h6 style={{ marginBottom: 30 }}>
                                Agora, para que você consiga realizar suas
                                compras com mais segurança, pedimos que efetue o
                                primeiro acesso na plataforma, utilizando o
                                e-mail e a senha cadastrados, e anexe a
                                documentação necessária para realizarmos a
                                análise do seu cadastro:
                            </h6>
                            <h6 style={{ fontWeight: 'bold' }}>
                                Contrato social da empresa
                            </h6>
                            <h6 style={{ fontWeight: 'bold' }}>
                                CPF e RG do administrador da empresa
                            </h6>
                            <h6
                                style={{ fontWeight: 'bold', marginBottom: 30 }}
                            >
                                Comprovante de endereço da empresa
                            </h6>
                            <h6>
                                Se você tiver qualquer dúvida sobre este
                                processo, basta nos contatar no e-mail{' '}
                                <a href="mailto:cadastros@nexen.com.br">
                                    cadastros@nexen.com.br
                                </a>
                                .
                            </h6>
                        </div>
                        <StyledButton
                            className="btn-nexen shadow-none"
                            block
                            style={{
                                fontSize: 18,
                                background:
                                    'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                                width: '100%',
                            }}
                            onClick={() => history.push(signInRoute.path)}
                        >
                            Acessar Plataforma
                        </StyledButton>
                    </StyledCol>
                </Row>
            );
        }

        if (isLoading) {
            return (
                <Row>
                    <Col md={1} />
                    <Col md={10} className="mb-4">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <StyledImage
                                src={heart}
                                style={{ marginRight: 10 }}
                            />
                            <StyledSpan>Bem vindo à NEXEN</StyledSpan>
                        </div>

                        <h4 className="d-flex flex-column mt-4">
                            <span>Finalização</span>

                            <StepProgressBar step={step} />
                        </h4>

                        <HeaderTitle>Concluindo cadastro...</HeaderTitle>
                        <hr />
                        <div className="mt-4 mb-4">
                            <h6
                                style={{
                                    marginBottom: 30,
                                    fontStyle: 'italic',
                                }}
                            >
                                Fundada em 2018, no Sul do Brasil, a Nexen tem o
                                propósito de levar energia limpa para todos.
                                Trazemos novos jeitos de fazer e pensar, para
                                que cada um conquiste sua autonomia. Assim,
                                ajudamos a criar um futuro no qual todos possam
                                gerar sua própria energia.
                            </h6>
                            <h6
                                style={{
                                    marginBottom: 30,
                                    fontStyle: 'italic',
                                }}
                            >
                                Para isso, trabalhamos com soluções em energia
                                fotovoltaica para residências, estabelecimentos
                                comerciais, indústrias e também para o
                                agronegócio.
                            </h6>
                            <h6
                                style={{
                                    marginBottom: 35,
                                    fontStyle: 'italic',
                                    fontWeight: 'bold',
                                }}
                            >
                                Seja bem-vindo à Nexen, é um prazer ter você
                                conosco nessa jornada!
                            </h6>
                        </div>

                        <StyledButton
                            block
                            style={{
                                fontSize: 18,
                                background:
                                    'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                            }}
                            className="btn-nexen shadow-none"
                            disabled
                        >
                            <Spinner animation="border" />
                        </StyledButton>
                    </Col>
                </Row>
            );
        }

        return <></>;
    };

    return (
        <AccessLayout>
            {showSuccessMessage || isLoading ? (
                renderExtraContent()
            ) : (
                <Row>
                    <Col md={1} />
                    <Col md={10} className="mb-4">
                        <HeaderAccess
                            setStep={
                                step === 1
                                    ? undefined
                                    : () => {
                                          setStep(step - 1);
                                      }
                            }
                            goBackTo="./select-module-registration"
                        />

                        <h4 className="d-flex flex-column mt-4">
                            <span>Passo {step} de 5</span>

                            <StepProgressBar step={step} />
                        </h4>

                        {step === 1 && (
                            <HeaderTitle>Dados Pessoais</HeaderTitle>
                        )}

                        {step === 2 && (
                            <>
                                <HeaderTitle>
                                    Dados do Administrador da Empresa
                                </HeaderTitle>
                                <h5>
                                    Responsável legal com autoridade para
                                    assinar documentos em nome da empresa
                                </h5>
                            </>
                        )}

                        {step === 3 && (
                            <HeaderTitle>Dados Empresariais</HeaderTitle>
                        )}

                        {step === 4 && (
                            <HeaderTitle>Dados de Localização</HeaderTitle>
                        )}

                        {step === 5 && <HeaderTitle>Quase lá!</HeaderTitle>}

                        <hr />

                        <Form className="mt-4" onSubmit={handleSubmit}>
                            {step === 1 && (
                                <div>
                                    <IntegratorUser
                                        signUp
                                        integrator={integrator}
                                        handleChanges={handleChanges}
                                        errors={errors}
                                    />

                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check
                                            type="checkbox"
                                            className="ml-1 float-left"
                                            onChange={(e: any) => {
                                                setAcceptTerms(
                                                    e.target.checked,
                                                );
                                            }}
                                            onClick={() => {
                                                setAcceptTerms(!acceptTerms);
                                            }}
                                        />

                                        <p className="float-left">
                                            Eu concordo com os{' '}
                                            {
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#707070',
                                                        fontWeight: 700,
                                                    }}
                                                    onClick={() =>
                                                        setShowModal(true)
                                                    }
                                                >
                                                    termos e condições de uso
                                                </span>
                                            }
                                        </p>
                                    </Form.Group>

                                    <StyledButton
                                        className="btn-nexen shadow-none"
                                        block
                                        style={{
                                            fontSize: 18,
                                            background:
                                                'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                                        }}
                                        disabled={!acceptTerms}
                                        onClick={goToSecondStep}
                                    >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Próximo passo
                                            <Image
                                                className="ml-2"
                                                src={smallWhiteRightArrow}
                                            />
                                        </div>
                                    </StyledButton>
                                </div>
                            )}

                            {step === 2 && (
                                <div>
                                    <IntegratorPartner
                                        signUp
                                        errors={errors}
                                        integrator={integrator}
                                        handleChanges={handleChanges}
                                    />

                                    <StyledButton
                                        block
                                        className="btn-nexen shadow-none"
                                        style={{
                                            fontSize: 18,
                                            background:
                                                'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                                        }}
                                        onClick={goToThirdStep}
                                    >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Próximo passo
                                            <Image
                                                className="ml-2"
                                                src={smallWhiteRightArrow}
                                            />
                                        </div>
                                    </StyledButton>
                                </div>
                            )}

                            {step === 3 && (
                                <div>
                                    <IntegratorCompany
                                        signUp
                                        errors={errors}
                                        integrator={integrator}
                                        handleChanges={handleChanges}
                                    />

                                    <StyledButton
                                        block
                                        className="btn-nexen shadow-none"
                                        style={{
                                            fontSize: 18,
                                            background:
                                                'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                                        }}
                                        onClick={goToFourthStep}
                                    >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Próximo passo
                                            <Image
                                                className="ml-2"
                                                src={smallWhiteRightArrow}
                                            />
                                        </div>
                                    </StyledButton>
                                </div>
                            )}

                            {step === 4 && (
                                <div>
                                    <IntegratorAddress
                                        signUp
                                        integrator={integrator}
                                        handleChanges={handleChanges}
                                        errors={errors}
                                    />

                                    <StyledButton
                                        block
                                        className="btn-nexen shadow-none"
                                        style={{
                                            fontSize: 18,
                                            background:
                                                'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                                        }}
                                        onClick={goToFifthStep}
                                    >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Próximo passo
                                            <Image
                                                className="ml-2"
                                                src={smallWhiteRightArrow}
                                            />
                                        </div>
                                    </StyledButton>
                                </div>
                            )}

                            {step === 5 && (
                                <div>
                                    <IntegratorCompanyInfo
                                        signUp
                                        integrator={integrator}
                                        handleChanges={handleChanges}
                                        errors={errors}
                                    />

                                    <StyledButton
                                        block
                                        type="submit"
                                        style={{
                                            fontSize: 18,
                                            background:
                                                'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                                        }}
                                        className="btn-nexen shadow-none"
                                        disabled={isLoading}
                                    >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Concluir Cadastro
                                            <Image
                                                className="ml-2"
                                                src={smallWhiteRightArrow}
                                            />
                                        </div>
                                    </StyledButton>
                                </div>
                            )}
                        </Form>
                    </Col>
                </Row>
            )}
            <UserTerms
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
            />
        </AccessLayout>
    );
};

export default SignUp;
