import React, { useEffect, useState } from 'react';
import { Badge, Col, Row, Spinner } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import IntegratorDocuments, {
    Attachment,
} from '../../components/Integrator/IntegratorDocuments';
import sellerStatus from '../../constants/sellerStatus';
import { AuthValues, useAuth } from '../../contexts/authContext';
import { StyledPageTitle } from '../../styles/pageTitle';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { StyledH5, StyledSaveButton } from './styles';
import IntegratorHttpService from '../../services/http/integrator-http';

const Documents: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)',
    });

    const isExtraSmallScreen = useMediaQuery({
        query: '(max-width: 319px)',
    });

    const { user }: AuthValues = useAuth();
    const [loading, setLoading] = useState(false);
    const [integratorDocuments, setIntegratorDocuments] = useState<
        Attachment[]
    >([]);

    async function handleSubmit(): Promise<void> {
        setLoading(true);

        const invalidDocument = integratorDocuments.filter(
            (document: Attachment) => !document.type,
        );

        if (invalidDocument.length > 0) {
            toast.error('Todos os documentos devem possuir um tipo associado');
            setLoading(false);

            return;
        }

        try {
            const response = await IntegratorHttpService.updateAuth({
                attachments: integratorDocuments.map((document) => ({
                    ...document,
                    __type: document.id ? 'update' : 'create',
                })),
            });

            if (response?.data?.attachments) {
                setIntegratorDocuments(response.data.attachments);
            }

            setLoading(false);
            toast.success('Cadastro do integrador alterado com sucesso');
        } catch (error) {
            console.log(error);

            if (isFriendlyHttpError(error)) {
                const err: any = error;

                setLoading(false);
                toast.error(err.message);
            } else {
                setLoading(false);
                toast.error('Erro ao alterar cadastro do integrador');
            }
        }
    }

    const getStatusBadge = () => {
        switch (user.seller?.status) {
            case sellerStatus.Pending:
                return <Badge variant="info">CADASTRO EM ANÁLISE</Badge>;
            case sellerStatus.Reproved:
                return <Badge variant="danger">CADASTRO REPROVADO</Badge>;
            default:
                return <Badge variant="warning">PROCESSANDO CADASTRO</Badge>;
        }
    };

    useEffect(() => {
        setIntegratorDocuments(user.seller?.attachments);
    }, [user.seller?.attachments]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col className="mt-3 mb-4">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <StyledPageTitle className="mt-2">
                                {user.seller?.name.toUpperCase()}
                            </StyledPageTitle>
                            {getStatusBadge()}
                        </div>
                    </div>
                </Col>
                <Col
                    // eslint-disable-next-line no-nested-ternary
                    xs={isDesktopOrLaptop ? 3 : isExtraSmallScreen ? 6 : 4}
                    className="mt-2 mb-3"
                >
                    <StyledSaveButton
                        disabled={loading}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={handleSubmit}
                        className="my-2"
                    >
                        {loading ? (
                            <Spinner animation="border" />
                        ) : (
                            'Salvar alterações'
                        )}
                    </StyledSaveButton>
                </Col>
            </Row>

            <>
                <Row className="mt-4 pr-2 pl-2">
                    <Col>
                        {user.seller?.status === sellerStatus.Reproved && (
                            <h6 style={{ color: 'red' }} className="mt-2 mb-4">
                                {`Motivo da reprovação: ${
                                    user.seller?.observation
                                        ? user.seller?.observation
                                        : 'Não informado'
                                }`}
                            </h6>
                        )}
                        <StyledH5 weight="normal" className="mt-3 mb-4">
                            Prezado integrador, solicitamos que realize, por
                            gentileza, o envio dos documentos indicados abaixo,
                            para que a nossa equipe possa realizar a análise do
                            seu cadastro.
                        </StyledH5>
                        <StyledH5 weight="normal" className="mb-4">
                            Caso tenha dúvidas sobre este processo, você pode
                            entrar em contato conosco através do e-mail{' '}
                            <a href="mailto:cadastros@nexen.com.br">
                                cadastros@nexen.com.br
                            </a>
                            .
                        </StyledH5>
                    </Col>
                </Row>

                <hr />

                <Row className="mt-4 pl-2 pr-2">
                    <Col>
                        <IntegratorDocuments
                            integratorDocuments={integratorDocuments}
                            setIntegratorDocuments={setIntegratorDocuments}
                        />
                    </Col>
                </Row>
            </>
        </BaseLayout>
    );
};

export default Documents;
