import { ListInvertersParams } from './kit-http';
import RequestV2 from './request-v2';

export default class ProductHttpService {
    private static uri = 'product';

    public static findAll(params: {}) {
        return RequestV2.get(`${this.uri}`, {
            ...params,
            withoutStorage: true,
        });
    }

    public static index(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static getModules(data: any) {
        return RequestV2.get(`${this.uri}/modules`, data);
    }

    public static storeModule(data: any) {
        if ('id' in data) {
            return RequestV2.put(`${this.uri}/modules/${data.id}`, data);
        }
        return RequestV2.post(`${this.uri}/modules`, data);
    }

    public static deleteModule(id: number) {
        return RequestV2.del(`${this.uri}/modules/${id}`);
    }

    public static getRoofTypes(data: any) {
        return RequestV2.get(`${this.uri}/roof-types`, data);
    }

    public static getInverters(params: ListInvertersParams) {
        return RequestV2.get(`${this.uri}/inverters`, params);
    }

    public static getInverterBrands(params?: any) {
        return RequestV2.get(`${this.uri}/inverter/brand`, params);
    }
}
