import { useQuery } from 'react-query';
import Role from '../constants/roles';
import { SelectOption } from '../components/Select';
import UserHttpService from '../services/http/user-http';
import userHasRoles from '../utils/userHasRoles';
import { AuthValues, useAuth } from '../contexts/authContext';

interface UserResponse {
    id: number;
    name: string;
}

export default function useCommercialConsultants() {
    const { user: authUser }: AuthValues = useAuth();
    const hasCommercialConsultantsFilterAccess = userHasRoles(authUser, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Registration,
    ]);

    const getCommercialConsultants = async (): Promise<
        Array<SelectOption<number>>
    > => {
        const response = await UserHttpService.readMany({
            role: Role.Commercial,
            hasSingleRole: true,
        });

        return response.data.data.map((user: UserResponse) => ({
            value: user.id,
            label: user.name,
        }));
    };

    const { data: commercialConsultants, isLoading } = useQuery({
        queryKey: ['commercial-consultants'],
        queryFn: getCommercialConsultants,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        enabled: hasCommercialConsultantsFilterAccess,
    });

    return { commercialConsultants, isLoading };
}
