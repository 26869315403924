import Request from './request';
import RequestV2 from './request-v2';

export default class LibraryHttpService {
    private static uri = 'libraries';

    private static uriV2 = 'library';

    public static getLibraries(data: any) {
        return RequestV2.get(`${this.uriV2}`, data);
    }

    public static getLibrary(id: number, data?: any) {
        return Request.get(`${this.uri}/${id}`, data);
    }

    public static storeLibrary(data: {}) {
        return Request.post(`${this.uri}`, data);
    }

    public static patchLibrary(id: number, data: {}) {
        return Request.put(`${this.uri}/${id}`, data);
    }

    public static deleteLibrary(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }

    public static getModules(data: any) {
        return RequestV2.get(`${this.uriV2}/module`, data);
    }

    public static getModule(id: number, data?: any) {
        return Request.get(`${this.uri}/modules/${id}`, data);
    }

    public static storeModule(data: {}) {
        return Request.post(`${this.uri}/modules`, data);
    }

    public static patchModule(id: number, data: {}) {
        return Request.patch(`${this.uri}/modules/${id}`, data);
    }

    public static deleteModule(id: number) {
        return Request.del(`${this.uri}/modules/${id}`);
    }

    public static getBrands(data: any) {
        return RequestV2.get(`${this.uriV2}/brand`, data);
    }

    public static getBrand(id: number, data?: any) {
        return Request.get(`${this.uri}/brands/${id}`, data);
    }

    public static storeBrand(data: {}) {
        return Request.post(`${this.uri}/brands`, data);
    }

    public static patchBrand(id: number, data: {}) {
        return Request.patch(`${this.uri}/brands/${id}`, data);
    }

    public static deleteBrand(id: number) {
        return Request.del(`${this.uri}/brands/${id}`);
    }

    public static getBrandsCategories(data: any) {
        return RequestV2.get(`${this.uriV2}/brand/category`, data);
    }

    public static getBrandCategory(id: number, data?: any) {
        return Request.get(`${this.uri}/brands/categories/${id}`, data);
    }

    public static storeBrandCategory(data: {}) {
        return Request.post(`${this.uri}/brands/categories`, data);
    }

    public static patchBrandCategory(id: number, data: {}) {
        return Request.patch(`${this.uri}/brands/categories/${id}`, data);
    }

    public static deleteBrandCategory(id: number) {
        return Request.del(`${this.uri}/brands/categories/${id}`);
    }

    public static getItems(data: any) {
        return RequestV2.get(`${this.uriV2}/item`, data);
    }

    public static getItem(id: number, data?: any) {
        return RequestV2.get(`${this.uriV2}/item/${id}`, data);
    }

    public static storeItem(data: {}) {
        return RequestV2.post(`${this.uriV2}/item`, data);
    }

    public static patchItem(id: number, data: {}) {
        return RequestV2.patch(`${this.uriV2}/item/${id}`, data);
    }

    public static deleteItem(id: number) {
        return RequestV2.del(`${this.uriV2}/item/${id}`);
    }

    public static storeFiles(file: {}) {
        return RequestV2.post(`${this.uriV2}/files`, file);
    }
}
