/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DigitalDocumentStatus from '../../../constants/digitalDocumentStatus';
import { documentTypes } from '../../../constants/documents';
import sellerStatus from '../../../constants/sellerStatus';
import history from '../../../services/history';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import {
    StyledButton,
    StyledCheckboxP,
    StyledDiv,
    StyledFlexDiv,
    StyledForm,
    StyledH4,
    StyledH5,
    StyledH6,
    StyledRestartButton,
} from './styles';
import IntegratorHttpService from '../../../services/http/integrator-http';
import BrasilService from '../../../services/http/brasil.http';

interface Props {
    id: string;
    show: boolean;
    onHide(): void;
    reset(): void;
}

const IntegratorDigitalContractModal = (props: Props) => {
    const newIntegrator: any = () => ({
        id: '',
        name: '',
        cnpj: '',
        status: '',
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        erpState: '',
        erpCityName: '',
        partnerName: '',
        partnerDocument: '',
        partnerBirthDate: '',
        partnerEmail: '',
        partnerPhone: '',
        digitalContract: {
            status: '',
            signedFileUrlAws: '',
        },
    });

    const hide = props.onHide;
    const id = props.id;
    const [integrator, setIntegrator] = useState(newIntegrator());
    const [userConsent, setUserConsent] = useState(false);
    const [userCancelConsent, setUserCancelConsent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    useEffect(() => {
        async function loadIntegrator(): Promise<void> {
            try {
                const response = await IntegratorHttpService.readOne(id);

                const { data } = response;

                const states = await BrasilService.getStates();

                const state = states.find(
                    (item) => item.sigla === data.erpState,
                );

                const document = data.clickSignDocuments?.find(
                    (item: any) =>
                        item.documentType === documentTypes.TermsAndConditions,
                );

                setIntegrator({
                    id,
                    name: data.name,
                    cnpj: data.cnpj,
                    status: data.status,
                    erpCityName: data.erpCityName,
                    erpState: state?.nome || '',
                    addressCep: data.addressCep?.toString() || '',
                    addressNumber: data.addressNumber,
                    addressDescription: data.addressDescription,
                    addressNeighborhood: data.addressNeighborhood,
                    partnerName: data.companyInfo?.partnerName,
                    partnerDocument: data.companyInfo?.partnerDocument,
                    partnerBirthDate: data.companyInfo?.partnerBirthDate,
                    partnerEmail: data.companyInfo?.partnerEmail,
                    partnerPhone: data.companyInfo?.partnerPhone,
                    digitalContract: document
                        ? {
                              status: document.status,
                              signedFileUrlAws: document.signedFileUrlAws,
                          }
                        : null,
                });
            } catch (error) {
                hide();
                console.error(error);
                toast.error('Erro ao buscar dados do integrador');
            }
        }

        if (id) {
            loadIntegrator();
        }
    }, [id, hide]);

    async function handleClick(): Promise<void> {
        setLoading(true);

        const shouldGenerateContract = !integrator.digitalContract?.status;

        if (shouldGenerateContract) {
            try {
                await IntegratorHttpService.createClicksignDocument(id);

                toast.success('Contrato digital gerado com sucesso');
            } catch (error: any) {
                hide();
                props.reset();

                const message = isFriendlyHttpError(error)
                    ? error.message
                    : 'Erro ao gerar o contrato digital';

                toast.error(message);

                return;
            }
        }

        hide();
        props.reset();

        try {
            await IntegratorHttpService.createClicksignDocumentNotification(id);

            toast.success('Notificação enviada com sucesso');
        } catch (error: any) {
            const message = isFriendlyHttpError(error)
                ? error.message
                : 'Erro ao enviar a notificação';

            toast.error(message);
        }
    }

    async function handleDeleteClick(): Promise<void> {
        setLoadingDelete(true);

        try {
            await IntegratorHttpService.deleteClicksignDocument(id);

            toast.success('Processo reiniciado com sucesso');
        } catch (error: any) {
            toast.error('Erro ao reiniciar o processo');
        }

        hide();
        props.reset();
    }

    const goToViewPage = async () => {
        history.push(`/integrators/${id}/view`);
    };

    const colors = {
        darkGrey: '#707070',
        lightGrey: '#bbbbbb',
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Gerar Contrato Digital
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <StyledH4>{integrator.name?.toUpperCase()}</StyledH4>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <StyledH5 weight="bold">Dados da Empresa</StyledH5>

                        <StyledH6 color={colors.lightGrey}>
                            RAZÃO SOCIAL
                        </StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.name?.toUpperCase() || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>CNPJ</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.cnpj
                                ? `${integrator.cnpj.slice(
                                      0,
                                      2,
                                  )}.${integrator.cnpj.slice(
                                      2,
                                      5,
                                  )}.${integrator.cnpj.slice(
                                      5,
                                      8,
                                  )}/${integrator.cnpj.slice(
                                      8,
                                      12,
                                  )}-${integrator.cnpj.slice(12, 14)}`
                                : '-'}
                        </StyledH6>

                        <StyledH5 weight="bold" style={{ marginTop: '30px' }}>
                            Dados do Administrador
                        </StyledH5>

                        <StyledH6 color={colors.lightGrey}>
                            NOME COMPLETO
                        </StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.partnerName?.toUpperCase() || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>CPF</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.partnerDocument || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>
                            DATA DE NASCIMENTO
                        </StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.partnerBirthDate
                                ? `${integrator.partnerBirthDate.slice(
                                      -2,
                                  )}/${integrator.partnerBirthDate.slice(
                                      5,
                                      -3,
                                  )}/${integrator.partnerBirthDate.slice(0, 4)}`
                                : '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>E-MAIL</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.partnerEmail?.toLowerCase() || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>WHATSAPP</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.partnerPhone || '-'}
                        </StyledH6>
                    </Col>
                    <Col>
                        <StyledH5 weight="bold">Endereço</StyledH5>

                        <StyledH6 color={colors.lightGrey}>LOGRADOURO</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.addressDescription?.toUpperCase() ||
                                '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>NÚMERO</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.addressNumber?.toUpperCase() || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>BAIRRO</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.addressNeighborhood?.toUpperCase() ||
                                '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>CIDADE</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.erpCityName?.toUpperCase() || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>ESTADO</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.erpState?.toUpperCase() || '-'}
                        </StyledH6>

                        <StyledH6 color={colors.lightGrey}>CEP</StyledH6>
                        <StyledH6 color={colors.darkGrey}>
                            {integrator.addressCep
                                ? `${integrator.addressCep.slice(
                                      0,
                                      5,
                                  )}-${integrator.addressCep?.slice(5, 8)}`
                                : '-'}
                        </StyledH6>
                    </Col>
                </Row>
            </Modal.Body>

            {integrator.id ? (
                <Modal.Footer style={{ padding: '16px' }}>
                    {integrator.digitalContract?.status ===
                        DigitalDocumentStatus.Signed && (
                        <StyledDiv>
                            <StyledH5
                                style={{
                                    textAlign: 'center',
                                    marginBottom: '15px',
                                }}
                                weight="bold"
                            >
                                Já existe um contrato digital assinado para este
                                integrador
                            </StyledH5>

                            <StyledButton type="button" onClick={goToViewPage}>
                                Visualizar/Resolver
                            </StyledButton>

                            {integrator.status === sellerStatus.Pending && (
                                <StyledFlexDiv>
                                    <StyledForm>
                                        <Form.Group controlId="checkboxUserCancelConsent">
                                            <Form.Check
                                                type="checkbox"
                                                onChange={(e: any) => {
                                                    setUserCancelConsent(
                                                        e.target.checked,
                                                    );
                                                }}
                                            />
                                        </Form.Group>
                                    </StyledForm>

                                    <StyledCheckboxP
                                        weight={
                                            userCancelConsent
                                                ? 'bold'
                                                : 'normal'
                                        }
                                    >
                                        Após verificadas inconsistências no
                                        contrato assinado, eu desejo reiniciar o
                                        processo do contrato digital para este
                                        integrador
                                    </StyledCheckboxP>

                                    <StyledRestartButton
                                        type="button"
                                        onClick={() => handleDeleteClick()}
                                        disabled={
                                            !userCancelConsent ||
                                            loadingDelete ||
                                            loading
                                        }
                                    >
                                        {loadingDelete ? (
                                            <Spinner animation="border" />
                                        ) : (
                                            'Reiniciar processo'
                                        )}
                                    </StyledRestartButton>
                                </StyledFlexDiv>
                            )}
                        </StyledDiv>
                    )}

                    {integrator.digitalContract?.status ===
                        DigitalDocumentStatus.Sent && (
                        <StyledDiv>
                            <StyledH5
                                style={{ textAlign: 'center' }}
                                weight="bold"
                            >
                                Já existe um contrato digital em andamento para
                                este integrador
                            </StyledH5>

                            <StyledFlexDiv>
                                <StyledForm>
                                    <Form.Group
                                        controlId="checkboxUserConsent"
                                        style={{ margin: '0' }}
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            checked={userConsent}
                                            onChange={(e: any) => {
                                                setUserConsent(
                                                    e.target.checked,
                                                );
                                                setUserCancelConsent(
                                                    e.target.checked
                                                        ? false
                                                        : userCancelConsent,
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </StyledForm>

                                <StyledCheckboxP
                                    style={{ width: '95%' }}
                                    weight={userConsent ? 'bold' : 'normal'}
                                >
                                    Eu desejo enviar uma nova notificação para o
                                    e-mail do administrador alertando a
                                    necessidade de assinatura
                                </StyledCheckboxP>
                            </StyledFlexDiv>

                            <StyledButton
                                type="button"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={handleClick}
                                disabled={
                                    !userConsent || loading || loadingDelete
                                }
                            >
                                {loading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    'Enviar Notificação para Assinatura'
                                )}
                            </StyledButton>

                            <StyledFlexDiv>
                                <StyledForm>
                                    <Form.Group controlId="checkboxUserCancelConsent">
                                        <Form.Check
                                            type="checkbox"
                                            checked={userCancelConsent}
                                            onChange={(e: any) => {
                                                setUserCancelConsent(
                                                    e.target.checked,
                                                );
                                                setUserConsent(
                                                    e.target.checked
                                                        ? false
                                                        : userConsent,
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </StyledForm>

                                <StyledCheckboxP
                                    weight={
                                        userCancelConsent ? 'bold' : 'normal'
                                    }
                                >
                                    Após verificadas inconsistências, eu desejo
                                    cancelar o contrato digital em andamento e
                                    reiniciar o processo para este integrador
                                </StyledCheckboxP>

                                <StyledRestartButton
                                    type="button"
                                    onClick={() => handleDeleteClick()}
                                    disabled={
                                        !userCancelConsent ||
                                        loadingDelete ||
                                        loading
                                    }
                                >
                                    {loadingDelete ? (
                                        <Spinner animation="border" />
                                    ) : (
                                        'Cancelar contrato'
                                    )}
                                </StyledRestartButton>
                            </StyledFlexDiv>
                        </StyledDiv>
                    )}

                    {!integrator.digitalContract?.status && (
                        <StyledDiv>
                            <StyledFlexDiv style={{ marginTop: '10px' }}>
                                <StyledForm>
                                    <Form.Group
                                        controlId="checkboxUserConsent"
                                        style={{ margin: '0' }}
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e: any) => {
                                                setUserConsent(
                                                    e.target.checked,
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </StyledForm>

                                <StyledCheckboxP
                                    style={{ width: '95%' }}
                                    weight={userConsent ? 'bold' : 'normal'}
                                >
                                    Eu verifiquei as informações apresentadas e
                                    desejo gerar um novo contrato digital para
                                    este integrador
                                </StyledCheckboxP>
                            </StyledFlexDiv>

                            <StyledButton
                                type="button"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={handleClick}
                                disabled={
                                    !userConsent || loading || loadingDelete
                                }
                            >
                                {loading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    'Gerar Contrato Digital e Enviar Notificação para Assinatura'
                                )}
                            </StyledButton>
                        </StyledDiv>
                    )}
                </Modal.Footer>
            ) : (
                <Modal.Footer
                    style={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Spinner animation="border" />
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default IntegratorDigitalContractModal;
