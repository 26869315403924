import RequestV2 from './request-v2';

export default class NotificationHttpService {
    private static uri = 'notification';

    public static readMany() {
        return RequestV2.get(this.uri, { take: 50, page: 1 });
    }

    public static update(id: number) {
        return RequestV2.patch(`${this.uri}/${id}`);
    }

    public static async delete(id: number) {
        return RequestV2.del(`${this.uri}/${id}`);
    }

    public static async deleteMany() {
        return RequestV2.del(`${this.uri}`);
    }
}
