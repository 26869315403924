import React, { useRef, useState } from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Image,
    Row,
    Spinner,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useForm } from 'react-hook-form';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaEraser } from 'react-icons/fa6';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import filterIcon from '../../assets/icons/filter.png';
import BaseLayout from '../../components/BaseLayout';
import UserFilter, { UserFilterProps } from '../../components/User/UserFilter';
import NotificationIcon from '../../constants/notificationIcon';
import { rowsPerPage } from '../../constants/pagination';
import RoleType from '../../constants/roleType';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { userCreateRoute, userEditRoute } from '../../routes/config';
import RegionHttpService from '../../services/http/region-http';
import RoleHttpService from '../../services/http/role-http';
import UserHttpService from '../../services/http/user-http';
import RegionsData from '../../shared/interfaces/region.interface';
import RolesData from '../../shared/interfaces/role.interface';
import UsersData from '../../shared/interfaces/user.interface';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { StyledSpan, StyledTable, StyledTh } from './styles';

interface PagesData {
    data: UsersData[];
    currentPage: number;
    pages: number;
}

const List: React.FC = () => {
    const [filterToggle, setFilterToggle] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [showRemoveUserModal, setShowRemoveUserModal] =
        useState<boolean>(false);

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<UserFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
            filterRoles: [],
            filterRegions: [],
        },
    });

    const filterData = watch();

    const loadRoles = async () => {
        const response = await RoleHttpService.readMany({
            roleType: RoleType.RegularUserRoles,
        });

        return response.data.data.map((role: any) => ({
            value: role.id,
            label: role.name,
            reference: role.reference,
        }));
    };

    const roles = useQuery('roles', loadRoles, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    const loadRegion = async () => {
        const params = {
            term: '',
        };
        const response = await RegionHttpService.readMany(params);

        return response.data.map((region: any) => ({
            label: region.name,
            value: region.id,
            states: region.states,
        }));
    };

    const regions = useQuery('regions', loadRegion, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    async function loadUsers({ pageParam = 0 }): Promise<{
        data: UsersData[];
        currentPage: number;
        pages: number;
    }> {
        const params = {
            term: filterData.filterTerm || '',
            skip: pageParam,
            take: rowsPerPage,
            roles: filterData.filterRoles
                ? filterData.filterRoles
                      .map((item: any) => item.value)
                      .join(',')
                : '',
            regions: filterData.filterRegions
                ? filterData.filterRegions
                      .map((item: any) => item.value)
                      .join(',')
                : '',
            roleType: RoleType.RegularUserRoles,
        };

        const response = await UserHttpService.readMany(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.total / rowsPerPage),
        };
    }

    const users = useInfiniteQuery(
        [
            'productDeliveryDates',
            filterData.filterTerm,
            filterData.filterRoles,
            filterData.filterRegions,
        ],
        loadUsers,
        {
            getNextPageParam: (response) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }

                return null;
            },
            staleTime: 300000,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
        },
    );

    const loadMoreRef = useRef(null);

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: users.fetchNextPage,
        enabled: users.hasNextPage,
    });

    const showLoading =
        users.isLoading || users.isFetchingNextPage || !users.isFetched;

    const noData = !(
        users?.data?.pages &&
        users.data.pages.length &&
        users.data.pages[0].data.length
    );

    const deleteUser = async () => {
        try {
            if (!selectedUserId) {
                throw new Error('Usuário não encontrado para exclusão');
            }
            await UserHttpService.delete(selectedUserId);

            toast.success('Usuário removido com sucesso');

            users.refetch();
        } catch (err) {
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao remover o usuário.');
        }
    };

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Usuários</StyledPageTitle>
                    <StyledPageSubTitle>
                        Todas as informações dos usuários em um só lugar.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <ButtonGroup className="float-right">
                        {isDirty && (
                            <Button
                                style={{
                                    color: '#2F80ED',
                                }}
                                className="m-2"
                                variant="text"
                                onClick={() => reset()}
                            >
                                <FaEraser
                                    fill="#2F80ED"
                                    className="mr-2"
                                    style={{
                                        color: '#2F80ED',
                                        width: '21px',
                                        height: '21px',
                                    }}
                                />{' '}
                                Limpar filtros
                            </Button>
                        )}
                        <Button
                            className="mr-1"
                            variant="light"
                            style={{
                                backgroundColor: '#EEEEEE',
                            }}
                            onClick={() => setFilterToggle(!filterToggle)}
                        >
                            <Image src={filterIcon} />
                        </Button>
                        <Link to={userCreateRoute.path}>
                            <Button className="float-right">
                                <i className="fas fa-plus" /> Novo Usuário
                            </Button>
                        </Link>
                    </ButtonGroup>
                </Col>
            </Row>
            <UserFilter
                filterToggle={filterToggle}
                control={control}
                roles={roles.data}
                regions={regions.data}
            />

            <Row className="pl-2 pr-2">
                <Col>
                    <StyledTable
                        bordered
                        hover
                        size="sm"
                        className="text-center"
                        style={{ maxWidth: '100%', minWidth: '100%' }}
                    >
                        <thead>
                            <tr>
                                <StyledTh>NOME</StyledTh>
                                <StyledTh>EMAIL</StyledTh>
                                <StyledTh>PERMISSÕES</StyledTh>
                                <StyledTh>REGIÕES</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {users.data?.pages &&
                                users.data?.pages?.map((page: PagesData) =>
                                    page?.data.map((user: UsersData) => (
                                        <tr key={user.id}>
                                            <td>
                                                <Highlighter
                                                    highlightClassName="highlight-term"
                                                    searchWords={[
                                                        filterData.filterTerm,
                                                    ]}
                                                    textToHighlight={user.name}
                                                    autoEscape
                                                />
                                            </td>
                                            <td
                                                style={{
                                                    wordWrap: 'break-word',
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                <Highlighter
                                                    highlightClassName="highlight-term"
                                                    searchWords={[
                                                        filterData.filterTerm,
                                                    ]}
                                                    textToHighlight={user.email}
                                                    autoEscape
                                                />
                                            </td>
                                            <td>
                                                <h5>
                                                    {user.roles.map(
                                                        (role: RolesData) => (
                                                            <Badge
                                                                style={{
                                                                    margin: '1%',
                                                                    backgroundColor:
                                                                        'rgba(47, 204, 139, 0.1)',
                                                                    color: ' #2fcc8b',
                                                                }}
                                                                variant="light"
                                                                key={role.id}
                                                            >
                                                                <Highlighter
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={
                                                                        role.name
                                                                    }
                                                                    autoEscape
                                                                />
                                                            </Badge>
                                                        ),
                                                    )}
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    {user.regions.map(
                                                        (
                                                            region: RegionsData,
                                                        ) => (
                                                            <Badge
                                                                style={{
                                                                    margin: '1%',
                                                                    backgroundColor:
                                                                        'rgba(47, 204, 139, 0.1)',
                                                                    color: ' #2fcc8b',
                                                                }}
                                                                variant="light"
                                                                key={region.id}
                                                            >
                                                                <Highlighter
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={
                                                                        region.name
                                                                    }
                                                                    autoEscape
                                                                />
                                                            </Badge>
                                                        ),
                                                    )}
                                                </h5>
                                            </td>

                                            <td>
                                                <Dropdown key="left">
                                                    <Dropdown.Toggle
                                                        bsPrefix="nexen"
                                                        as={Button}
                                                        variant="text"
                                                    >
                                                        <BsThreeDotsVertical
                                                            fill="#bdbdbd"
                                                            style={{
                                                                width: '25px',
                                                                height: '30px',
                                                            }}
                                                        />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            as={Link}
                                                            to={userEditRoute.build(
                                                                user,
                                                            )}
                                                        >
                                                            Editar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setSelectedUserId(
                                                                    user.id,
                                                                );
                                                                setShowRemoveUserModal(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            Remover
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )),
                                )}
                        </tbody>
                    </StyledTable>
                </Col>

                <Col md={12} className="text-center" ref={loadMoreRef}>
                    {showLoading && (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    )}
                    {!showLoading && noData && (
                        <Col md={12} className="text-center">
                            <StyledSpan>Sem itens para carregar</StyledSpan>
                        </Col>
                    )}
                </Col>

                {showRemoveUserModal && (
                    <ConfirmationDialog
                        show={showRemoveUserModal}
                        onHide={() => {
                            setShowRemoveUserModal(false);
                            setSelectedUserId(null);
                        }}
                        onConfirm={deleteUser}
                        icon={NotificationIcon.Warning}
                        title="Excluir usuário"
                        text="Deseja excluir o usuário selecionado?"
                    />
                )}
            </Row>
        </BaseLayout>
    );
};

export default List;
