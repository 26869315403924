import React, { useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    Image,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { Controller, useForm } from 'react-hook-form';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ReactComponent as ReactCheckIcon } from '../../../../assets/icons/check.svg';
import { ReactComponent as ReactCloseIcon } from '../../../../assets/icons/close_red.svg';
import { ReactComponent as ReactEmptyIcon } from '../../../../assets/icons/empty.svg';
import { ReactComponent as EraserIcon } from '../../../../assets/icons/eraser-solid.svg';
import filterIcon from '../../../../assets/icons/filter.png';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import ProductDeliveryStatus from '../../../../constants/productDeliveryStatus';
import { useConfiguration } from '../../../../contexts/configurationContext';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import ProductDeliveryDateHttpService from '../../../../services/http/product-delivery-date-http';
import Branch from '../../../../shared/interfaces/branch.interface';
import Product from '../../../../shared/interfaces/product.interface';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../styles/react-select-config';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import formatDate from '../../../../utils/formatDate';
import formatDateWithHours from '../../../../utils/formatDateWithHours';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import { StyledTh } from '../styles';
import ProductDeliveryDateEditor from './ProductDeliveryDateEditor/ProductDeliveryDateEditor';
import {
    OptionProps,
    ProductDeliveryDateFilter,
    ProductDeliveryDateFilterProps,
} from './ProductDeliveryDateFilter';
import ProductDeliveryDateInsertManyModal from './InsertManyModal';
import ProductDeliveryDateDeleteManyModal from './DeleteManyModal';
import BranchHttpService from '../../../../services/http/branch-http';
import ProductHttpService from '../../../../services/http/product.http';

interface ProductDeliveryDateData {
    boardingDate: string;
    createdAt: string;
    deletedAt: string;
    id: number;
    lastUpdate: string;
    productDeliveryUpdateStatus: string;
    productDescription: string;
    productErpCode: string;
    productOrderProcessNumber: string;
    productOrigin: string;
    productQuantity: number;
    tenantid: string;
    updatedAt: string;
    recordOrigin: string;
}

const ProductDeliveryDateTable: React.FC = () => {
    const rowsPerPage = 25;
    const {
        showSelectedProductDeliveryDateEditorModal,
        setShowSelectedProductDeliveryDateEditorModal,
    } = useConfiguration();
    const [selectedProductDeliveryDateId, setSelectedProductDeliveryDateId] =
        useState<number | null>(null);
    const [
        showRemoveProductDeliveryDateModal,
        setShowRemoveProductDeliveryDateModal,
    ] = useState<boolean>(false);

    const [filterToggle, setFilterToggle] = useState(false);
    const [
        showProductDeliveryDateInsertManyModal,
        setShowProductDeliveryDateInsertManyModal,
    ] = useState(false);

    const [
        showProductDeliveryDateDeleteManyModal,
        setShowProductDeliveryDateDeleteManyModal,
    ] = useState(false);

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<ProductDeliveryDateFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
            filterProduct: [],
            filterOrderProcess: '',
            filterBranch: [],
            filterOrigin: null,
            filterStartDate: '',
            filterEndDate: '',
            filterOrderBy: {
                label: 'Código ERP',
                value: 'productErpCode',
            },
            filterOrderType: {
                label: 'Crescente',
                value: 'ASC',
            },
        },
    });

    const filterData = watch();

    const formatFilterDate = (date: any) => {
        if (date) {
            return new Date(date.toString()).toISOString().split('T', 1)[0];
        }

        return '';
    };

    async function loadProductDeliveryDates({ pageParam = 0 }): Promise<{
        data: ProductDeliveryDateData[];
        currentPage: number;
        pages: number;
    }> {
        const params = {
            term: filterData.filterTerm || '',
            skip: pageParam,
            take: rowsPerPage,
            productFilter: filterData.filterProduct
                ? filterData.filterProduct
                      .map((item: any) => item.value)
                      .join(',')
                : '',
            orderProcessFilter: filterData.filterOrderProcess || '',
            branchFilter: filterData.filterBranch
                ? filterData.filterBranch
                      .map((item: any) => item.value)
                      .join('+')
                : '',
            originFilter: filterData.filterOrigin
                ? filterData.filterOrigin.value
                : '',
            startDate: formatFilterDate(filterData.filterStartDate),
            endDate: formatFilterDate(filterData.filterEndDate),
            orderBy: filterData.filterOrderBy
                ? filterData.filterOrderBy.value
                : '',
            orderType: filterData.filterOrderType
                ? filterData.filterOrderType.value
                : '',
        };

        const response = await ProductDeliveryDateHttpService.index(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.meta.total / rowsPerPage),
        };
    }

    const dates = useInfiniteQuery(
        [
            'productDeliveryDates',
            filterData.filterTerm,
            filterData.filterProduct,
            filterData.filterBranch,
            filterData.filterOrderProcess,
            filterData.filterStartDate,
            filterData.filterEndDate,
            filterData.filterOrderBy,
            filterData.filterOrderType,
            filterData.filterOrigin,
        ],
        loadProductDeliveryDates,
        {
            getNextPageParam: (response) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }

                return null;
            },
            staleTime: 300000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );

    const loadMoreRef = useRef(null);

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: dates.fetchNextPage,
        enabled: dates.hasNextPage,
    });

    const showLoading =
        dates.isLoading || dates.isFetchingNextPage || !dates.isFetched;

    const noData = !(
        dates?.data?.pages &&
        dates.data.pages.length &&
        dates.data.pages[0].data.length
    );

    async function loadBranches() {
        const response = await BranchHttpService.readMany({});

        const branches: OptionProps[] = [];

        if (response?.data?.data) {
            response.data.data.map((branch: Branch) =>
                branches.push({
                    label: branch.name,
                    value: `${branch.companyCode},${branch.branchCode}`,
                }),
            );
        }

        return branches;
    }

    const branches = useQuery('branches', loadBranches, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    async function loadProducts() {
        const response = await ProductHttpService.findAll({});

        const products: OptionProps[] = [];

        if (response?.data?.data) {
            response.data.data.map((product: Product) =>
                products.push({
                    label: `${product.B1_COD} - ${product.B1_DESC}`,
                    value: product.B1_COD,
                }),
            );
        }

        return products;
    }

    const products = useQuery('products', loadProducts, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    const mutationRemove = useMutation(
        async () => {
            if (!selectedProductDeliveryDateId) {
                throw new Error(
                    'Data de disponibilidade não encontrada para exclusão',
                );
            }

            ProductDeliveryDateHttpService.destroy(
                selectedProductDeliveryDateId,
            );
        },
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao excluir o produto');
            },
            onSuccess: () => {
                toast.success('Produto removido com sucesso');
            },
            onSettled: () => {
                dates.refetch();
            },
        },
    );

    const getReturnStatusIcon = (status: string) => {
        if (status === ProductDeliveryStatus.Success) {
            return (
                <ReactCheckIcon
                    fill="#00AA00"
                    className="ml-1"
                    width="18"
                    height="18"
                />
            );
        }
        if (status === ProductDeliveryStatus.Error) {
            return <ReactCloseIcon className="ml-0" width="18" height="18" />;
        }
        return (
            <ReactEmptyIcon
                color="#707070"
                className="ml-0"
                width="18"
                height="18"
            />
        );
    };

    const termTrimed = filterData.filterTerm.trim();

    return (
        <>
            <Row className="header align-items-center justify-content-between pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Disponibilidade
                    </StyledPageTitle>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-4" controlId="formBasicOrderBy">
                        <Form.Label>Ordernar por</Form.Label>

                        <Controller
                            className="form-control-nexen"
                            as={Select}
                            control={control}
                            name="filterOrderBy"
                            options={[
                                {
                                    label: 'Disponibilidade',
                                    value: 'boardingDate',
                                },
                                {
                                    label: 'Código ERP',
                                    value: 'productErpCode',
                                },
                            ]}
                            styles={{
                                control: tradeControl,
                                option: optionsTheme,
                            }}
                            theme={theme}
                            placeholder="Selecione..."
                        />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-4" controlId="formBasicOrderType">
                        <Form.Label>Ordem</Form.Label>

                        <Controller
                            className="form-control-nexen"
                            as={Select}
                            control={control}
                            name="filterOrderType"
                            options={[
                                {
                                    label: 'Decrescente',
                                    value: 'DESC',
                                },
                                {
                                    label: 'Crescente',
                                    value: 'ASC',
                                },
                            ]}
                            styles={{
                                control: tradeControl,
                                option: optionsTheme,
                            }}
                            theme={theme}
                            placeholder="Selecione..."
                        />
                    </Form.Group>
                </Col>
                <ButtonGroup>
                    {isDirty && (
                        <Button
                            style={{
                                color: '#2F80ED',
                            }}
                            className="m-2"
                            variant="text"
                            onClick={() => reset()}
                        >
                            <EraserIcon
                                fill="#2F80ED"
                                className="mr-2"
                                width={18}
                            />{' '}
                            Limpar filtros
                        </Button>
                    )}
                    <Button
                        className="m-2"
                        variant="light"
                        style={{ backgroundColor: '#EEEEEE' }}
                        onClick={() => setFilterToggle(!filterToggle)}
                    >
                        <Image src={filterIcon} />
                    </Button>
                </ButtonGroup>
                <Button
                    className="float-right m-2"
                    style={{ width: '15em' }}
                    onClick={() => {
                        setShowProductDeliveryDateInsertManyModal(true);
                    }}
                >
                    Inclusão em lote
                </Button>
                <Button
                    className="float-right m-2"
                    style={{ width: '15em' }}
                    onClick={() => {
                        setShowProductDeliveryDateDeleteManyModal(true);
                    }}
                >
                    Exclusão em lote
                </Button>
                <Button
                    className="float-right m-2"
                    style={{ width: '15em' }}
                    onClick={() => {
                        setShowSelectedProductDeliveryDateEditorModal(true);
                    }}
                >
                    Novo produto
                </Button>
            </Row>

            <ProductDeliveryDateFilter
                filterToggle={filterToggle}
                control={control}
                branches={branches?.data || []}
                disableBranch={branches.isLoading}
                products={products?.data || []}
                disableProduct={products.isLoading}
            />

            <Row className="mt-2">
                <Col md={12}>
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh>Cód. ERP</StyledTh>
                                <StyledTh>Descrição</StyledTh>
                                <StyledTh>Disponibilidade</StyledTh>
                                <StyledTh>Filial</StyledTh>
                                <StyledTh>Quantidade</StyledTh>
                                <StyledTh>Origem</StyledTh>
                                <StyledTh>Nº processo/pedido</StyledTh>
                                <StyledTh>Última atualização</StyledTh>
                                <StyledTh>Origem inclusão</StyledTh>
                                <StyledTh>Status</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {dates.data?.pages &&
                                dates.data?.pages?.map((page: any) =>
                                    page?.data.map(
                                        (date: ProductDeliveryDateData) => (
                                            <tr key={date.id}>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimed,
                                                        ]}
                                                        textToHighlight={
                                                            date.productErpCode ||
                                                            '-'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimed,
                                                        ]}
                                                        textToHighlight={
                                                            date.productDescription ||
                                                            '-'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    {formatDate(
                                                        date.boardingDate,
                                                    )}
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimed,
                                                        ]}
                                                        textToHighlight={
                                                            date.tenantid || '-'
                                                        }
                                                    />
                                                </td>
                                                <td>{date.productQuantity}</td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimed,
                                                        ]}
                                                        textToHighlight={
                                                            date.productOrigin ||
                                                            '-'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimed,
                                                        ]}
                                                        textToHighlight={
                                                            date.productOrderProcessNumber ||
                                                            '-'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    {formatDateWithHours(
                                                        date.lastUpdate,
                                                    )}
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            termTrimed,
                                                        ]}
                                                        textToHighlight={
                                                            date.recordOrigin ||
                                                            '-'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    {getReturnStatusIcon(
                                                        date.productDeliveryUpdateStatus,
                                                    )}
                                                </td>
                                                <td>
                                                    <Dropdown key="left">
                                                        <Dropdown.Toggle
                                                            bsPrefix="nexen"
                                                            className="mt-n3 mb-n3"
                                                            as={Button}
                                                            variant="text"
                                                        >
                                                            <ReactMoreIcon
                                                                fill="#bdbdbd"
                                                                width="10"
                                                                height="20"
                                                            />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setSelectedProductDeliveryDateId(
                                                                        date.id,
                                                                    );
                                                                    setShowSelectedProductDeliveryDateEditorModal(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                Editar
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setSelectedProductDeliveryDateId(
                                                                        date.id,
                                                                    );
                                                                    setShowRemoveProductDeliveryDateModal(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                Remover
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </Col>

                <Col md={12} className="text-center" ref={loadMoreRef}>
                    {showLoading && (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    )}
                    {!showLoading && noData && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>

            <ProductDeliveryDateEditor
                show={showSelectedProductDeliveryDateEditorModal}
                id={selectedProductDeliveryDateId}
                onHide={() => {
                    setShowSelectedProductDeliveryDateEditorModal(false);
                    setSelectedProductDeliveryDateId(null);
                }}
                refetch={() => dates.refetch()}
                branches={branches?.data || []}
                disableBranch={branches.isLoading}
                products={products?.data || []}
                disableProduct={products.isLoading}
            />

            {showRemoveProductDeliveryDateModal && (
                <ConfirmationDialog
                    show={showRemoveProductDeliveryDateModal}
                    onHide={() => setShowRemoveProductDeliveryDateModal(false)}
                    onConfirm={async () => mutationRemove.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir data de disponibilidade"
                    text="Deseja excluir data de disponibilidade selecionada?"
                />
            )}

            {showProductDeliveryDateInsertManyModal && (
                <ProductDeliveryDateInsertManyModal
                    show={showProductDeliveryDateInsertManyModal}
                    onHide={() => {
                        setShowProductDeliveryDateInsertManyModal(false);
                        dates.refetch();
                    }}
                />
            )}

            {showProductDeliveryDateDeleteManyModal && (
                <ProductDeliveryDateDeleteManyModal
                    show={showProductDeliveryDateDeleteManyModal}
                    onHide={() => {
                        setShowProductDeliveryDateDeleteManyModal(false);
                        dates.refetch();
                    }}
                />
            )}
        </>
    );
};

export default ProductDeliveryDateTable;
