import Request from './request';
import RequestV2 from './request-v2';

export default class ProductDeliveryDateHttpService {
    private static uri = 'product-delivery-dates';

    public static uriV2 = 'product/delivery-date';

    public static async index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static async show(id: string) {
        return Request.get(`${this.uri}/${id}`);
    }

    public static async store(data: {}) {
        return RequestV2.post(this.uriV2, data);
    }

    public static async update(data: {}) {
        return Request.patch(this.uri, data);
    }

    public static async destroy(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }

    public static async import(csv: FormData) {
        return Request.post(`${this.uri}/import`, csv, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static async removeByRecordOrigin(recordOrigin: string) {
        return Request.del(`${this.uri}?recordOrigin=${recordOrigin}`);
    }
}
