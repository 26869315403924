import sellerStatus from '../../constants/sellerStatus';
import RequestV2 from './request-v2';

export default class IntegratorHttpService {
    private static uri = 'users/integrator';

    public static create(data: {}) {
        return RequestV2.post(`${this.uri}`, data);
    }

    public static readMany(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static readOne(id: string) {
        return RequestV2.get(`${this.uri}/${id}`);
    }

    public static updateStatus(
        id: number,
        status: sellerStatus,
        observation?: string,
    ) {
        return RequestV2.patch(`${this.uri}/${id}/status`, {
            origin: 'MANAGEMENT',
            status,
            observation,
        });
    }

    public static updateAuth(data: {}) {
        return RequestV2.put(this.uri, data);
    }

    public static update(id: string, data: {}) {
        return RequestV2.put(`${this.uri}/${id}`, data);
    }

    public static createClicksignDocument(id: string) {
        return RequestV2.post(`${this.uri}/${id}/clicksign-document`);
    }

    public static createClicksignDocumentNotification(id: string) {
        return RequestV2.post(
            `${this.uri}/${id}/clicksign-document/notification`,
        );
    }

    public static updateClicksignDocument(id: string) {
        return RequestV2.patch(`${this.uri}/${id}/clicksign-document`);
    }

    public static deleteClicksignDocument(id: string) {
        return RequestV2.del(`${this.uri}/${id}/clicksign-document`);
    }
}
