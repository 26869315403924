import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BaseLayout from '../../components/BaseLayout';
import UserHttpService from '../../services/http/user-http';
import { contributorListRoute } from '../../routes/config';
import history from '../../services/history';
import RoleHttpService from '../../services/http/role-http';
import { userRules } from '../../validations/integrator';
import getValidationsErrors from '../../utils/getValidationsErrors';
import { StyledH4, StyledLink } from './styles';
import { theme, control } from '../../styles/react-select-config';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import NotificationMethods from '../../constants/notificationMethods';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import Role from '../../constants/roles';
import sellerStatus from '../../constants/sellerStatus';
import userHasRoles from '../../utils/userHasRoles';
import { AuthValues, useAuth } from '../../contexts/authContext';
import RoleType from '../../constants/roleType';
import IntegratorHttpService from '../../services/http/integrator-http';

const styles = {
    multiValue: (base: any, state: any) =>
        state.data.isFixed
            ? {
                  ...base,
                  backgroundColor: 'gray',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base,
    multiValueLabel: (base: any, state: any) =>
        state.data.isFixed
            ? {
                  ...base,
                  color: 'white',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base,
    multiValueRemove: (base: any, state: any) =>
        state.data.isFixed
            ? {
                  ...base,
                  display: 'none',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base,
};

const New: React.FC = () => {
    const newUser = () => ({
        id: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: [],
        regions: [],
        integrators: [],
        max_percentage_discount: '',
        notificationMethods: [],
    });

    const { id } = useParams<{ id: string }>();

    const formRef: any = useRef<FormHandles>(null);
    const [errors, setErrors] = useState(newUser());
    const [user, setUser] = useState(newUser());
    const [roles, setRoles] = useState([]);
    const [integrators, setIntegrators] = useState([]);
    const [userRegions, setUserRegions] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [userIntegrators, setUserIntegrators] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const { user: userLogado }: AuthValues = useAuth();

    const isIntegratorOrManager = userHasRoles(userLogado, [
        Role.Integrator,
        Role.Manager,
    ]);

    const [notificationMethodsList, setNotificationMethodsList] = useState(
        [] as any,
    );
    const [notificationMethods, setNotificationMethods] = useState([
        {
            label: 'Aplicação',
            value: NotificationMethods.Aplicacao,
            isFixed: true,
        },
    ] as any);

    useEffect(() => {
        setNotificationMethodsList([
            ...notificationMethods,
            ...[
                {
                    label: 'E-mail',
                    value: NotificationMethods.Email,
                    isFixed: false,
                },
            ],
        ]);

        const loadRoles = async () => {
            const results = await RoleHttpService.readMany({
                roleType: RoleType.ManagerOrContributorRoles,
            });

            setRoles(
                results.data.data
                    .filter((role: any) => role.reference !== Role.Integrator)
                    .map((role: any) => ({
                        value: role.id,
                        label: role.name,
                        reference: role.reference,
                    })),
            );
        };

        loadRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadIntegrators = async () => {
        const params = {
            statusFilter: sellerStatus.Approved,
            isShortList: true,
        };

        const responseInt = await IntegratorHttpService.readMany(params);

        const mappedIntegrators = responseInt.data.data.map((item: any) => ({
            value: item.id,
            label: item.name,
        }));

        setIntegrators(mappedIntegrators);
    };

    useEffect(() => {
        loadIntegrators();
    }, []);

    useEffect(() => {
        async function loadUser(): Promise<void> {
            const response = await UserHttpService.readOne(id);

            const { data } = response;

            setUser({
                id: data.id,
                username: data.name,
                email: data.email,
                password: '',
                password_confirmation: '',
                roles: [],
                regions: [],
                integrators: [],
                max_percentage_discount: data.maxPercentageDiscount,
                notificationMethods: [],
            });

            const mappedRoles = data.roles.map((item: any) => ({
                value: item.id,
                label: item.name,
                reference: item.reference,
            }));

            const regions = data.regions.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));

            const splittedNotificationMethods =
                data.notificationMethods.split(',');

            const newNoti = splittedNotificationMethods.map((item: any) => {
                let label = '';
                let value = '';
                let isFixed = false;

                if (item.trim() === NotificationMethods.Aplicacao) {
                    label = 'Aplicação';
                    value = NotificationMethods.Aplicacao;
                    isFixed = true;
                }

                if (item.trim() === NotificationMethods.Email) {
                    label = 'E-mail';
                    value = NotificationMethods.Email;
                    isFixed = false;
                }

                return {
                    value,
                    label,
                    isFixed,
                };
            });

            const ints = [
                { value: data?.owner?.id, label: data?.owner?.name },
            ] as any;

            setUserIntegrators(ints);

            setUserRegions(regions);
            setUserRoles(mappedRoles);
            setNotificationMethods(newNoti);
        }

        if (id) {
            loadUser();
        }
    }, [id]);

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            if (
                item.target &&
                item.target.name === 'max_percentage_discount' &&
                (item.target.value > 100 || item.target.value < 0)
            ) {
                return;
            }

            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });

        setUser({ ...user, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const handleChangesNotificationMethods = (
        value: any,
        { action, removedValue }: any,
    ) => {
        if (action === 'pop-value' && removedValue.isFixed) {
            return;
        }

        let newNotificationMethods = value;

        if (action === 'clear') {
            newNotificationMethods = notificationMethods.filter(
                (v: any) => v.isFixed,
            );
        }

        setNotificationMethods(newNotificationMethods);
    };

    const prepareData = (data: any) => {
        const userData = {
            id: data.id,
            name: data.username,
            email: data.email,
            password: data.password ? data.password : undefined,
            roles: userRoles?.map((item: any) => item.reference),
            regions: userRegions?.map((item: any) => item.value),
            notificationMethods: notificationMethods?.map(
                (item: any) => item.value,
            ),
            ownerId: null,
            discounts: [],
        };

        if (userIntegrators.length > 0) {
            userData.ownerId = userIntegrators[0].value;
        }

        return userData;
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            const schema = Yup.object().shape(userRules);

            const filteredRoles = userRoles?.filter((item: any) =>
                item ? item.value : false,
            );

            if (filteredRoles.length === 0) {
                toast.error('Preencha as permissões!');
                throw new Error('Preencha as permissões!');
            }

            await schema.validate(user, { abortEarly: false, context: user });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            return;
        }

        try {
            setLoading(true);

            const data = prepareData(user);

            if (!isIntegratorOrManager && data.ownerId === null) {
                toast.error('O integrador associado não foi informado');
                return;
            }

            let response = null;

            if (user.id) {
                response = await UserHttpService.update(user.id, data);
            } else {
                response = await UserHttpService.create(data);
            }

            if (response) {
                history.push(contributorListRoute.path);
                toast.success('Colaborador salvo com sucesso!');
            }
        } catch (err) {
            const error = err as any;

            console.log(err);

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao salvar dados do colaborador!');
            return;
        } finally {
            setLoading(false);
        }
    };

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Colaboradores
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Todas as informações dos colaboradores em um só lugar.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button disabled={loading} onClick={handleSubmit}>
                        {loading && <Spinner size="sm" animation="border" />}
                        {id
                            ? 'Alterar informações do colaborador'
                            : 'Cadastrar colaborador'}
                    </Button>
                </Col>
            </Row>

            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <StyledH4>
                        <StyledLink to={contributorListRoute.path}>
                            <i className="fas fa-chevron-left mr-2" />
                            {user.username ? user.username : 'Cadastro'}
                        </StyledLink>
                    </StyledH4>
                    <p>Informações sobre o colaborador</p>
                </Col>
            </Row>

            <Row className="pl-2 pr-2">
                <Col>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <div>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    isInvalid={!!errors.username}
                                    onChange={handleChanges}
                                    value={user.username}
                                    name="username"
                                    type="text"
                                    placeholder="Informe o nome"
                                />
                                {errors.username && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    onChange={handleChanges}
                                    value={user.email}
                                    isInvalid={!!errors.email}
                                    name="email"
                                    type="email"
                                    placeholder="Informe seu e-mail"
                                />
                                {errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control
                                    isInvalid={!!errors.password}
                                    onChange={handleChanges}
                                    name="password"
                                    type="password"
                                    placeholder="Informe a senha"
                                />
                                {errors.password && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirmar senha</Form.Label>
                                <Form.Control
                                    isInvalid={!!errors.password_confirmation}
                                    onChange={handleChanges}
                                    name="password_confirmation"
                                    type="password"
                                    placeholder="Confirmar senha"
                                />
                                {errors.password_confirmation && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password_confirmation}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group controlId="formBasicIe">
                                <Form.Label>Permissões</Form.Label>

                                <Select
                                    onChange={(e: any, a: any) => {
                                        const newRoles = [a.option] as any;

                                        setUserRoles(newRoles);
                                    }}
                                    value={userRoles}
                                    styles={{ control }}
                                    options={roles}
                                    isMulti
                                    theme={theme}
                                />
                            </Form.Group>
                            {!isIntegratorOrManager && (
                                <Form.Group controlId="formBasicIe">
                                    <Form.Label>
                                        Integrador associado
                                    </Form.Label>
                                    <Select
                                        onChange={(e: any, a: any) => {
                                            let integrator: any[] = [];

                                            if (a.action === 'select-option') {
                                                integrator = [a.option];
                                            }

                                            setUserIntegrators(integrator);
                                        }}
                                        value={userIntegrators}
                                        styles={{ control }}
                                        options={integrators}
                                        isMulti
                                        theme={theme}
                                    />
                                </Form.Group>
                            )}

                            <Form.Group controlId="formBasicIe">
                                <Form.Label>Meios de notificação</Form.Label>

                                <Select
                                    onChange={(e, { action, removedValue }) =>
                                        handleChangesNotificationMethods(e, {
                                            action,
                                            removedValue,
                                        })
                                    }
                                    isClearable={notificationMethods?.some(
                                        (v: any) => !v.isFixed,
                                    )}
                                    value={notificationMethods}
                                    styles={styles}
                                    options={notificationMethodsList}
                                    isMulti
                                    theme={theme}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Col>
            </Row>
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { New };
