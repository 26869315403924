import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import sellerStatus from '../../../constants/sellerStatus';
import formatDate from '../../../utils/formatDate';
import formatMask from '../../../utils/fotmatMask';
import generatePartialCNPJs from '../../../utils/generatePartialCNPJs';
import SellerStatusBadge from '../SellerStatusBadge';
import useSellers from '../../../hooks/useSellers';
import SellerDigitalDocumentStatusBadge from '../SellerDigitalDocumentStatusBadge';
import SellerTableDropdown from '../SellerTableDropdown';
import Seller from '../../../shared/interfaces/seller.interface';
import SellerInternalNote from '../SellerInternalNote';
import { useAuth } from '../../../contexts/authContext';
import Role from '../../../constants/roles';
import userHasRoles from '../../../utils/userHasRoles';

interface SellerTableRowProps {
    seller: Seller;
    onUpdateDigitalContract: (id: number) => void;
}

export default function SellerTableRow({
    seller,
    onUpdateDigitalContract,
}: SellerTableRowProps) {
    const { sellerOnDigitalContractUpdate, filterForm } = useSellers();

    const filterData = filterForm.watch();

    const termTrimmed = filterData.filterTerm.trim();

    const hasValidCharsForCNPJ = /^[0-9.-/][^a-zA-Z]+$/i.test(termTrimmed);

    const partialCNPJs = hasValidCharsForCNPJ
        ? generatePartialCNPJs(termTrimmed)
        : [];

    const { user } = useAuth();

    const isAdministratorOrRegistration = userHasRoles(user, [
        Role.Administrator,
        Role.Registration,
    ]);

    return (
        <tr>
            <td>{seller.id}</td>
            <td>
                <h5>
                    {seller.status === sellerStatus.Disabled ? (
                        <OverlayTrigger
                            placement="bottom"
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Tooltip id="overlay-example">
                                    {seller.observation ||
                                        'Não foram encontrados dados sobre o motivo do bloqueio'}
                                </Tooltip>
                            }
                        >
                            <span>
                                <SellerStatusBadge status={seller.status} />
                            </span>
                        </OverlayTrigger>
                    ) : (
                        <SellerStatusBadge status={seller.status} />
                    )}
                </h5>
            </td>
            <td>
                {sellerOnDigitalContractUpdate &&
                sellerOnDigitalContractUpdate === seller.id ? (
                    <Spinner animation="border" />
                ) : (
                    <h5>
                        <SellerDigitalDocumentStatusBadge
                            digitalDocuments={seller.clickSignDocuments}
                        />
                    </h5>
                )}
            </td>
            <td>
                <span>{seller.hasAttachments ? 'Sim' : '-'}</span>
            </td>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={partialCNPJs}
                    textToHighlight={
                        formatMask(seller.cnpj, '##.###.###/####-##') as string
                    }
                />
            </td>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[termTrimmed]}
                    textToHighlight={seller.name}
                />
            </td>
            <td
                style={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                }}
            >
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[termTrimmed]}
                    textToHighlight={seller.user?.email || ''}
                />
            </td>
            <td>{`${seller.erpCityName}/${seller.erpState}`}</td>
            <td>{formatDate(seller.createdAt)}</td>

            <td className="d-flex align-items-center justify-content-center border-top-0 border-left-0 border-right-0">
                {isAdministratorOrRegistration && (
                    <SellerInternalNote seller={seller} />
                )}
                <SellerTableDropdown
                    seller={seller}
                    onUpdateDigitalContract={onUpdateDigitalContract}
                />
            </td>
        </tr>
    );
}
