import { AxiosResponse } from 'axios';
import EmailConfirmationRequest from '../../shared/interfaces/email-confirmation-request.interface';
import EmailConfirmation from '../../shared/interfaces/email-confirmation.interface';
import { Users } from '../../shared/interfaces/users.interface';
import Request from './request';
import RequestV2 from './request-v2';

export default class UserHttpService {
    private static uri = 'users';

    public static readMany(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static readOne(id: string) {
        return RequestV2.get(`${this.uri}/${id}`);
    }

    public static create(data: {}) {
        return RequestV2.post(this.uri, data);
    }

    public static update(id: string, data: {}) {
        return RequestV2.put(`${this.uri}/${id}`, data);
    }

    public static forgotPassword(email: string) {
        return RequestV2.post(`${this.uri}/password/${email}`);
    }

    public static updatePassword(data: {}) {
        return RequestV2.patch(`${this.uri}/password/`, data);
    }

    public static async delete(id: number) {
        return RequestV2.del(`${this.uri}/${id}`);
    }

    public static batchUpdate(users: Users) {
        return Request.patch(`${this.uri}`, users);
    }

    public static requestEmailConfirmation(
        data: EmailConfirmationRequest,
    ): Promise<AxiosResponse<void>> {
        return RequestV2.post(`${this.uri}/email/${data.email}`);
    }

    public static confirmEmail(
        data: EmailConfirmation,
    ): Promise<AxiosResponse<boolean>> {
        return RequestV2.patch(`${this.uri}/email`, data);
    }
}
