import React, { useRef, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import AccessLayout from '../../components/AccessLayout';
import HeaderAccess from '../../components/HeaderAccess';
import { resetPasswordRules } from '../../validations/integrator';
import history from '../../services/history';
import { signInRoute } from '../../routes/config';
import { StyledButton } from '../SignIn/styles';
import getValidationsErrors from '../../utils/getValidationsErrors';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import UserHttpService from '../../services/http/user-http';

const ForgotPassword: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [isLoading, setLoading] = useState(false);

    const newUser = () => ({
        password: '',
        password_confirmation: '',
    });

    const formRef: any = useRef<FormHandles>(null);
    const [errors, setErrors] = useState(newUser());
    const [user, setUser] = useState(newUser());

    const handleChanges = async (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        const rules: any = {};

        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;

            rules[item.target.name] = resetPasswordRules[item.target.name];
        });

        const updatedUser = { ...user, ...changes };

        setUser(updatedUser);

        const schema = Yup.object().shape(rules);

        try {
            await schema.validate(changes, {
                abortEarly: false,
                context: user,
            });

            setErrors({ ...errors, ...newErrors });
        } catch (error) {
            const err = error as any;

            setErrors({ ...errors, ...(getValidationsErrors(err) as any) });
        }
    };

    const prepareData = (data: any) => {
        const userData = {
            token,
            password: data.password,
        };

        return userData;
    };

    const reset = async () => {
        try {
            const schema = Yup.object().shape(resetPasswordRules);

            await schema.validate(user, { abortEarly: false, context: user });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            return;
        }

        try {
            const data = prepareData(user);

            const response = await UserHttpService.updatePassword(data);

            if (response) {
                history.push(signInRoute.path);

                toast.success('Senha alterada com sucesso!');
                return;
            }
        } catch (error) {
            const err = error as any;

            if (isFriendlyHttpError(err)) {
                toast.error(err.message);
                return;
            }

            toast.error('Erro ao tentar atualizar a senha!');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);

        await reset();

        setLoading(false);
    };

    return (
        <AccessLayout>
            <Row>
                <Col md={1} />
                <Col md={10}>
                    <HeaderAccess
                        setStep={undefined}
                        goBackTo={signInRoute.path}
                    />

                    <h4 className="mt-5">
                        <strong>Recuperar senha</strong>
                    </h4>
                    <p>Informe a nova senha de acesso.</p>
                    <hr />

                    <Form
                        ref={formRef}
                        className="mt-5"
                        onSubmit={handleSubmit}
                    >
                        <Form.Group controlId="formBasicPaerrorsssword">
                            <Form.Label>Nova senha</Form.Label>
                            <Form.Control
                                className="nexen-form-control"
                                isInvalid={!!errors.password}
                                onChange={handleChanges}
                                isValid={!!user.password && !errors.password}
                                name="password"
                                type="password"
                                placeholder="Informe a senha"
                            />
                            {errors.password && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group controlId="formBasicConfirmPassword">
                            <Form.Label>Confirmar nova senha</Form.Label>
                            <Form.Control
                                className="nexen-form-control"
                                isInvalid={!!errors.password_confirmation}
                                onChange={handleChanges}
                                isValid={
                                    !!user.password_confirmation &&
                                    !errors.password_confirmation
                                }
                                name="password_confirmation"
                                type="password"
                                placeholder="Confirmar senha"
                            />
                            {errors.password_confirmation && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.password_confirmation}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <StyledButton
                            className="btn-nexen"
                            disabled={isLoading}
                            block
                            variant="primary"
                            type="submit"
                        >
                            Alterar senha
                        </StyledButton>
                    </Form>
                </Col>
            </Row>
        </AccessLayout>
    );
};

export default ForgotPassword;
