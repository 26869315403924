import ConfigurationReference from '../../constants/configurationReference';
import Request from './request';
import RequestV2 from './request-v2';

export default class ConfigurationHttpService {
    private static uri = 'configurations';

    private static uriV2 = 'configuration';

    public static readOne(reference: ConfigurationReference) {
        return RequestV2.get(`${this.uri}/${reference}`);
    }

    public static update(
        reference: ConfigurationReference,
        configuration: { value: string | null },
    ) {
        return Request.patch(`${this.uri}/${reference}`, configuration);
    }
}
