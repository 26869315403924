import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import NotificationIcon from '../../../constants/notificationIcon';
import { rowsPerPage } from '../../../constants/pagination';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { StyledTh } from '../../../pages/Library/styles';
import LibraryHttpService from '../../../services/http/library-http';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';

interface Props {
    term: string;
    refresh: boolean;
    handleOpenModal: (id?: number) => void;
}

function Modules({ term, refresh, handleOpenModal }: Props) {
    const loadMoreRef = useRef();

    const [selectedLibraryModuleId, setSelectedLibraryModuleId] = useState<
        number | null
    >(null);
    const [showRemoveLibraryModuleModal, setShowRemoveLibraryModuleModal] =
        useState<boolean>(false);

    async function loadModules({ pageParam = 0 }: any): Promise<any> {
        const params = {
            term: term || '',
            skip: pageParam,
            take: rowsPerPage,
        };

        const { data } = await LibraryHttpService.getModules(params);

        return {
            data: data.data,
            currentPage: pageParam,
            pages: Math.ceil(data.total / rowsPerPage),
        };
    }

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        refetch,
        isFetchingNextPage,
    } = useInfiniteQuery('modules', loadModules, {
        // eslint-disable-next-line consistent-return
        getNextPageParam: (response) => {
            if (response.currentPage + 1 < response.pages) {
                return response.currentPage + 1;
            }
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    const remove = useMutation(
        async () => {
            if (!selectedLibraryModuleId) {
                throw new Error('Módulo não encontrado para exclusão');
            }

            LibraryHttpService.deleteModule(selectedLibraryModuleId);
        },
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);
                    return;
                }
                toast.error('Ocorreu um erro ao excluir este o módulo');
            },
            onSuccess: () => {
                toast.success('Módulo removido com sucesso');
            },
            onSettled: () => {
                refetch();
            },
        },
    );

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        if (refresh) {
            refetch();
            return;
        }

        const timer = setTimeout(() => {
            refetch();
        }, 500);

        // eslint-disable-next-line consistent-return
        return () => clearTimeout(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, term]);

    return (
        <Row className="pl-2 pr-2">
            <Col>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh style={{ minWidth: '150px' }}>
                                Nome
                            </StyledTh>
                            <StyledTh style={{ minWidth: '150px' }}>
                                Data de criação
                            </StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {data?.pages &&
                            data?.pages.map((group: any) =>
                                group?.data.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={item.name}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[]}
                                                textToHighlight={new Date(
                                                    item.createdAt,
                                                ).toLocaleDateString()}
                                            />
                                        </td>
                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    className="mt-n3 mb-n3"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <ReactMoreIcon
                                                        fill="#bdbdbd"
                                                        width="10"
                                                        height="20"
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleOpenModal(
                                                                item.id,
                                                            )
                                                        }
                                                    >
                                                        Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedLibraryModuleId(
                                                                item.id,
                                                            );
                                                            setShowRemoveLibraryModuleModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Remover
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )),
                            )}
                    </tbody>
                </Table>
            </Col>
            <Col md={12} className="text-center" ref={loadMoreRef as any}>
                {(isLoading || isFetchingNextPage) && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}
                {data?.pages[0].data.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Col>

            {showRemoveLibraryModuleModal && (
                <ConfirmationDialog
                    show={showRemoveLibraryModuleModal}
                    onHide={() => {
                        setShowRemoveLibraryModuleModal(false);
                        setSelectedLibraryModuleId(null);
                    }}
                    onConfirm={async () => remove.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir módulo"
                    text="Deseja excluir o módulo selecionado?"
                />
            )}
        </Row>
    );
}

export default Modules;
