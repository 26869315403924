import { useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Row,
    Spinner,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useForm } from 'react-hook-form';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaEraser } from 'react-icons/fa6';
import { FiFilter } from 'react-icons/fi';
import { useInfiniteQuery, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import NotificationIcon from '../../../../constants/notificationIcon';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import kitsHttpService from '../../../../services/http/kit-http';
import Accessory from '../../../../shared/interfaces/accessory.interface';
import Product from '../../../../shared/interfaces/product.interface';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import { SelectOption } from '../../../Select';
import { AccessoriesFilter, AccessoriesFilterProps } from './AccessoriesFilter';
import AccessoryEditor from './AccessoryEditor';
import { StyledTable, StyledTh } from './styles';
import ProductHttpService from '../../../../services/http/product.http';

interface PagesData {
    data: Accessory[];
    currentPage: number;
    pages: number;
}

export interface ListAccessoriesParams {
    skip: number;
    take: number;
    term: string;
    productFilter: string;
    accessoryFilter: string;
}

const AccessoriesTable = () => {
    const rowsPerPage = 20;
    const [showAccessoryEditorModal, setShowAccessoryEditorModal] =
        useState(false);

    const newAccessory: Accessory = {
        productErpCode: '',
        productName: '',
        accessoryErpCode: '',
        accessoryName: '',
        formula: '',
    };

    const [selectedAccessory, setSelectedAccessory] = useState(newAccessory);
    const [showRemoveConfirmation, setshowRemoveConfirmation] = useState(false);

    const [filterToggle, setFilterToggle] = useState(false);

    const [errors, setErrors] = useState({
        accessoryErpCode: '',
        productErpCode: '',
        formula: '',
    });

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<AccessoriesFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
            filterProduct: [],
            filterAccessory: [],
        },
    });

    const filterData = watch();

    async function loadAccessories({ pageParam = 0 }): Promise<{
        data: Accessory[];
        currentPage: number;
        pages: number;
    }> {
        const params: ListAccessoriesParams = {
            skip: pageParam,
            take: rowsPerPage,
            term: filterData.filterTerm || '',
            productFilter: filterData.filterProduct
                ? filterData.filterProduct
                      .map((item: SelectOption) => item.value)
                      .join(',')
                : '',
            accessoryFilter: filterData.filterAccessory
                ? filterData.filterAccessory
                      .map((item: SelectOption) => item.value)
                      .join(',')
                : '',
        };

        const response = await kitsHttpService.getAccessories(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.meta.total / rowsPerPage),
        };
    }

    const accessories = useInfiniteQuery(
        [
            'accessories',
            filterData.filterTerm,
            filterData.filterProduct,
            filterData.filterAccessory,
        ],
        loadAccessories,
        {
            getNextPageParam: (response) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }

                return null;
            },
            staleTime: 300000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );

    const loadMoreRef = useRef(null);

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: accessories.fetchNextPage,
        enabled: accessories.hasNextPage,
    });

    const showLoading =
        accessories.isLoading ||
        accessories.isFetchingNextPage ||
        !accessories.isFetched;

    const noData = !(
        accessories?.data?.pages &&
        accessories.data.pages.length &&
        accessories.data.pages[0].data.length
    );

    async function loadProducts() {
        const response = await ProductHttpService.findAll({});

        const products: Array<SelectOption<string>> = [];

        if (response?.data?.data) {
            response.data.data.data.forEach((product: Product) => {
                products.push({
                    label: `${product.B1_COD} - ${product.B1_DESC}`,
                    value: product.B1_COD,
                });
            });
        }

        return products;
    }

    const products = useQuery('products', loadProducts, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    async function deleteAccessory(accessory: Accessory) {
        try {
            if (accessory.id) {
                await kitsHttpService.deleteAccessory(accessory.id);
            }

            toast.success('Acessório excluído com sucesso');
            accessories.refetch();
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Acessórios
                    </StyledPageTitle>
                </Col>
                <ButtonGroup>
                    {isDirty && (
                        <Button
                            style={{
                                color: '#2F80ED',
                            }}
                            className="m-2"
                            variant="text"
                            onClick={() => reset()}
                        >
                            <FaEraser
                                fill="#2F80ED"
                                className="mr-2"
                                style={{
                                    color: '#2F80ED',
                                    width: '21px',
                                    height: '21px',
                                }}
                            />{' '}
                            Limpar filtros
                        </Button>
                    )}
                    <Button
                        className="m-2"
                        variant="light"
                        style={{ backgroundColor: '#EEEEEE' }}
                        onClick={() => setFilterToggle(!filterToggle)}
                    >
                        <FiFilter
                            fill="#bdbdbd"
                            style={{
                                color: '#bdbdbd',
                                width: '20px',
                                height: '20px',
                            }}
                        />
                    </Button>
                </ButtonGroup>
                <Button
                    className="float-right w-20 m-2"
                    style={{ width: '180px' }}
                    onClick={() => {
                        setSelectedAccessory(newAccessory);
                        setShowAccessoryEditorModal(true);
                    }}
                >
                    Novo acessório
                </Button>
            </Row>

            <AccessoriesFilter
                filterToggle={filterToggle}
                control={control}
                products={products?.data || []}
                disableProduct={products.isLoading}
            />

            <Row>
                <StyledTable bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>Cód. ERP Produto</StyledTh>
                            <StyledTh>Produto</StyledTh>
                            <StyledTh>Cód. ERP Acessório</StyledTh>
                            <StyledTh>Acessório</StyledTh>
                            <StyledTh>Fórmula</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {accessories.data?.pages &&
                            accessories.data?.pages?.map((page: PagesData) =>
                                page?.data.map((accessory: Accessory) => (
                                    <tr key={accessory.id}>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[
                                                    filterData.filterTerm,
                                                ]}
                                                textToHighlight={
                                                    accessory.productErpCode
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[
                                                    filterData.filterTerm,
                                                ]}
                                                textToHighlight={
                                                    accessory.productName
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[
                                                    filterData.filterTerm,
                                                ]}
                                                textToHighlight={
                                                    accessory.accessoryErpCode
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[
                                                    filterData.filterTerm,
                                                ]}
                                                textToHighlight={
                                                    accessory.accessoryName
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[
                                                    filterData.filterTerm,
                                                ]}
                                                textToHighlight={
                                                    accessory.formula
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    className="mt-n3 mb-n3"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <BsThreeDotsVertical
                                                        fill="#bdbdbd"
                                                        style={{
                                                            width: '25px',
                                                            height: '30px',
                                                        }}
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedAccessory(
                                                                accessory,
                                                            );
                                                            setShowAccessoryEditorModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedAccessory(
                                                                accessory,
                                                            );
                                                            setshowRemoveConfirmation(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Remover
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )),
                            )}
                    </tbody>
                </StyledTable>
            </Row>
            <Col md={12} className="text-center" ref={loadMoreRef}>
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}
                {!showLoading && noData && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Col>

            {showAccessoryEditorModal && (
                <AccessoryEditor
                    show={showAccessoryEditorModal}
                    selectedAccessory={selectedAccessory}
                    onHide={() => {
                        setSelectedAccessory(newAccessory);
                        setShowAccessoryEditorModal(false);
                        accessories.refetch();
                        setErrors({
                            accessoryErpCode: '',
                            productErpCode: '',
                            formula: '',
                        });
                    }}
                    errors={errors}
                    setErrors={setErrors}
                    products={products?.data || []}
                    disableProduct={products.isLoading}
                />
            )}

            {showRemoveConfirmation && (
                <ConfirmationDialog
                    show={showRemoveConfirmation}
                    onHide={() => setshowRemoveConfirmation(false)}
                    onConfirm={() => deleteAccessory(selectedAccessory)}
                    icon={NotificationIcon.Warning}
                    title="Excluir acessório"
                    text="Deseja excluir o acessório selecionado?"
                />
            )}
        </>
    );
};

export default AccessoriesTable;
