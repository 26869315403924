/* eslint-disable react/jsx-no-bind */
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import { integratorListRoute } from '../../routes/config';
import history from '../../services/history';
import IntegratorUser from '../../components/Integrator/IntegratorUser';
import IntegratorCompany from '../../components/Integrator/IntegratorCompany';
import IntegratorAddress from '../../components/Integrator/IntegratorAddress';
import {
    addressRules,
    companyRules,
    userRulesIntegrator,
    partnerRules,
    companyInfoRules,
} from '../../validations/integrator';
import getValidationsErrors from '../../utils/getValidationsErrors';
import { StyledH4, StyledLink } from './styles';
import { StyledNav } from '../../styles/nav';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import Role from '../../constants/roles';
import userHasRoles from '../../utils/userHasRoles';
import { useAuth } from '../../contexts/authContext';
import IntegratorCompanyInfo from '../../components/Integrator/IntegratorCompanyInfo';
import IntegratorPartner from '../../components/Integrator/IntegratorPartner';
import IntegratorDocuments, {
    Attachment,
} from '../../components/Integrator/IntegratorDocuments';
import IntegratorCommercialConsultants from '../../components/Integrator/IntegratorCommercialConsultants';
import { Integrator } from '../../shared/interfaces/integrator.interface';
import IntegratorTabs from '../../constants/integratorTabs';
import handleResponseError from '../../utils/handleResponseError';
import BrasilService from '../../services/http/brasil.http';
import IntegratorHttpService from '../../services/http/integrator-http';

interface Tab {
    key: IntegratorTabs;
    label: string;
    visible: boolean;
    element: ReactNode;
}

interface RouteParams {
    id: string;
}

// eslint-disable-next-line import/prefer-default-export
export function New() {
    const { user } = useAuth();

    const hasAccessToDocuments = userHasRoles(user, [
        Role.Administrator,
        Role.Registration,
    ]);

    const hasAccessToCommercialConsultants = userHasRoles(user, [
        Role.CommercialSupervisor,
        Role.Administrator,
        Role.Registration,
    ]);

    const { id } = useParams<RouteParams>();

    const newIntegrator = (): Integrator => ({
        id: '',
        integratorsNick: '',
        name: '',
        email: '',
        cnpj: '',
        level: '002',
        phone: '',
        profilePoints: 0,
        ie: '',
        hasIe: '1',
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: '',
        erpCity: '',
        erpCityName: '',
        fancyName: '',
        status: '',
        password: '',
        passwordConfirmation: '',
        partnerName: '',
        partnerDocument: '',
        partnerBirthDate: '',
        partnerEmail: '',
        partnerPhone: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
        canDoTriangulation: false,
        commercialConsultants: [],
    });

    const formRef: any = useRef<FormHandles>(null);
    const [selectedTabKey, setSelectedTabKey] = useState<IntegratorTabs>(
        IntegratorTabs.PersonalData,
    );
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(newIntegrator());
    const [loadingError, setLoadingError] = useState(false);
    const [integrator, setIntegrator] = useState<Integrator>(newIntegrator());
    const [integratorDocuments, setIntegratorDocuments] = useState<
        Attachment[]
    >([]);

    const prepareData = (data: Integrator) => {
        const integratorData = {
            id: data.id ? data.id : undefined,
            name: data.name,
            cnpj: data.cnpj,
            level: data.level,
            phone: data.phone,
            profilePoints: data.profilePoints,
            ie: data.ie,
            erpCity:
                typeof data.erpCity === 'object'
                    ? data.erpCity?.value
                    : data.erpCity,
            erpCityName:
                typeof data.erpCity === 'object'
                    ? data.erpCity?.label
                    : data.erpCityName,
            erpState:
                typeof data.erpState === 'object'
                    ? data.erpState.value
                    : data.erpState,
            addressCep: data.addressCep.toString().replace(/\D/g, ''),
            addressNumber: data.addressNumber,
            addressDescription: data.addressDescription,
            addressNeighborhood: data.addressNeighborhood,
            addressComplement: data.addressComplement,
            fancyName: data.fancyName,
            user: {
                name: data.integratorsNick,
                email: data.email,
                password: data.password ? data.password : undefined,
            },
            companyInfo: {
                partnerName: data.partnerName,
                partnerDocument: data.partnerDocument,
                partnerBirthDate: data.partnerBirthDate,
                partnerEmail: data.partnerEmail,
                partnerPhone: data.partnerPhone,
                question1: data.question1,
                question2: data.question2,
                question3: data.question3,
                question4: data.question4,
                question5: data.question5,
                question6: data.question6,
            },
            attachments: integratorDocuments.map((attachment) => ({
                ...attachment,
                __type: attachment.id ? 'update' : 'create',
            })),
            ...(data.canDoTriangulation !== null && {
                canDoTriangulation: data.canDoTriangulation,
            }),
            commercialConsultants: data.commercialConsultants,
        };

        return integratorData;
    };

    async function handleSubmit(): Promise<void> {
        setLoading(true);

        try {
            const schemaUser = Yup.object().shape(userRulesIntegrator);

            await schemaUser.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
            setLoading(false);

            setSelectedTabKey(IntegratorTabs.PersonalData);
            return;
        }

        try {
            const schemaCompany = Yup.object().shape(companyRules);

            await schemaCompany.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
            setLoading(false);

            setSelectedTabKey(IntegratorTabs.Cnpj);
            return;
        }

        try {
            const schemaAddress = Yup.object().shape(addressRules);

            await schemaAddress.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
            setLoading(false);

            setSelectedTabKey(IntegratorTabs.Address);
            return;
        }

        try {
            const schemaPartner = Yup.object().shape(partnerRules);

            await schemaPartner.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
            setLoading(false);
            setSelectedTabKey(IntegratorTabs.CompanyInfo);

            return;
        }

        try {
            const schemaCompanyInfo = Yup.object().shape(companyInfoRules);

            await schemaCompanyInfo.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);
            setLoading(false);
            setSelectedTabKey(IntegratorTabs.CompanyInfo);

            return;
        }

        const invalidDocument = integratorDocuments.filter(
            (document: Attachment) => !document.type,
        );

        if (invalidDocument.length > 0) {
            toast.error('Todos os documentos devem possuir um tipo associado');
            setLoading(false);
            setSelectedTabKey(IntegratorTabs.Documents);

            return;
        }

        const data = prepareData(integrator);

        try {
            await IntegratorHttpService.update(id, data);

            history.push(integratorListRoute.path);
            toast.success('Cadastro do integrador alterado com sucesso');
        } catch (error) {
            handleResponseError(
                error,
                'Erro ao alterar cadastro do integrador',
            );
        } finally {
            setLoading(false);
        }
    }

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};

        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            if (item.target.name === 'hasIe') {
                changes.ie = '';

                if (item.target.value === '0') {
                    changes.ie = 'ISENTO';
                }
            }

            if (item.target.name === 'erpState') {
                changes.erpState = item?.target?.value?.code;
                changes.erpCity = '';
            }

            if (item.target.name === 'triangulation') {
                changes.canDoTriangulation = !integrator.canDoTriangulation;

                return;
            }

            if (item.target.name === 'ie') {
                changes.ie = item.target.value?.replace(/\D/g, '');

                return;
            }

            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });

        setIntegrator({ ...integrator, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    useEffect(() => {
        async function loadIntegrator(): Promise<void> {
            try {
                const response = await IntegratorHttpService.readOne(id);
                const { data } = response;

                const states = await BrasilService.getStates();

                const state = states.find(
                    (item: any) => item.sigla === data.erpState,
                );

                let erpState = {};

                if (state) {
                    erpState = {
                        label: state.nome,
                        value: state.sigla,
                    };
                }

                setIntegrator({
                    id: data.id,
                    integratorsNick: data.user?.name,
                    name: data.name,
                    email: data.user?.email,
                    cnpj: data.cnpj,
                    level: data.level,
                    phone: data.phone,
                    profilePoints: data.profilePoints,
                    hasIe: data.ie === 'ISENTO' ? '0' : '1',
                    ie: data.ie,
                    erpCity: data.erpCity,
                    erpCityName: data.erpCityName,
                    erpState: erpState as any,
                    addressCep: data.addressCep?.toString(),
                    addressNumber: data.addressNumber,
                    addressDescription: data.addressDescription,
                    addressNeighborhood: data.addressNeighborhood,
                    addressComplement: data.addressComplement,
                    fancyName: data.fancyName,
                    status: data.status,
                    password: '',
                    passwordConfirmation: '',
                    partnerName: data.companyInfo?.partnerName,
                    partnerDocument: data.companyInfo?.partnerDocument,
                    partnerBirthDate: data.companyInfo?.partnerBirthDate,
                    partnerEmail: data.companyInfo?.partnerEmail,
                    partnerPhone: data.companyInfo?.partnerPhone,
                    question1: data.companyInfo?.question1,
                    question2: data.companyInfo?.question2,
                    question3: data.companyInfo?.question3,
                    question4: data.companyInfo?.question4,
                    question5: data.companyInfo?.question5,
                    question6: data.companyInfo?.question6,
                    canDoTriangulation: data.canDoTriangulation,
                    commercialConsultants:
                        data.commercialConsultants?.map(
                            (commercialConsultant: { id: number }) =>
                                commercialConsultant.id,
                        ) || [],
                });

                setIntegratorDocuments(data.attachments);
            } catch (error) {
                console.log(error);
                throw Error();
            }
        }

        if (id) {
            try {
                loadIntegrator();
            } catch (error) {
                setLoadingError(true);
            }
        }
    }, [id]);

    const handleCommercialConsultantsChange = (value: number[]) => {
        setIntegrator({
            ...integrator,
            commercialConsultants: value,
        });
    };

    const buttonText: string = id
        ? 'Salvar alterações'
        : 'Cadastrar integrador';

    const tabs: Tab[] = [
        {
            key: IntegratorTabs.PersonalData,
            label: 'Dados pessoais',
            visible: true,
            element: (
                <IntegratorUser
                    signUp={false}
                    errors={errors}
                    integrator={integrator}
                    handleChanges={handleChanges}
                    isSignUp={false}
                />
            ),
        },
        {
            key: IntegratorTabs.Cnpj,
            label: 'CNPJ',
            visible: true,
            element: (
                <IntegratorCompany
                    signUp={false}
                    errors={errors}
                    integrator={integrator}
                    handleChanges={handleChanges}
                />
            ),
        },
        {
            key: IntegratorTabs.Address,
            label: 'Endereço',
            visible: true,
            element: (
                <IntegratorAddress
                    signUp={false}
                    errors={errors}
                    integrator={integrator}
                    handleChanges={handleChanges}
                />
            ),
        },
        {
            key: IntegratorTabs.CompanyInfo,
            label: 'Informação da empresa',
            visible: true,
            element: (
                <>
                    <IntegratorPartner
                        signUp={false}
                        errors={errors}
                        integrator={integrator}
                        handleChanges={handleChanges}
                    />
                    <IntegratorCompanyInfo
                        signUp={false}
                        errors={errors}
                        integrator={integrator}
                        handleChanges={handleChanges}
                    />
                </>
            ),
        },
        {
            key: IntegratorTabs.CommecialConultants,
            label: 'Consultor comercial',
            visible: hasAccessToCommercialConsultants,
            element: (
                <IntegratorCommercialConsultants
                    selectedCommercialConsultants={
                        integrator.commercialConsultants
                    }
                    onCommercialConsultantsChange={
                        handleCommercialConsultantsChange
                    }
                />
            ),
        },
        {
            key: IntegratorTabs.Documents,
            label: 'Documentos',
            visible: hasAccessToDocuments,
            element: (
                <IntegratorDocuments
                    integratorDocuments={integratorDocuments}
                    setIntegratorDocuments={setIntegratorDocuments}
                />
            ),
        },
    ];

    const tabsFiltered: Tab[] = tabs.filter((tab: Tab) => tab.visible);

    const selectedTab = tabsFiltered.find(
        (currentTab) => currentTab.key === selectedTabKey,
    );

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <div className="d-flex flex-column">
                        <StyledPageTitle className="mt-2">
                            Integradores
                        </StyledPageTitle>
                        <StyledPageSubTitle>
                            Todas as informações dos integradores em um só
                            lugar.
                        </StyledPageSubTitle>
                    </div>
                </Col>
                {!loadingError && (
                    <Col className="text-right">
                        <Button disabled={loading} onClick={handleSubmit}>
                            {loading ? (
                                <Spinner animation="border" />
                            ) : (
                                buttonText
                            )}
                        </Button>
                    </Col>
                )}
            </Row>

            {loadingError ? (
                <Row className="pl-2 pr-2 mt-4">
                    <Col>
                        <h6>
                            NÃO FOI POSSÍVEL BUSCAR AS INFORMAÇÕES DO INTEGRADOR
                        </h6>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row className="pl-2 pr-2 mt-4">
                        <Col>
                            <StyledH4>
                                <StyledLink to={integratorListRoute.path}>
                                    <i className="fas fa-chevron-left mr-2" />
                                    {integrator.name || 'Cadastro'}
                                </StyledLink>
                            </StyledH4>
                            <p>Informações sobre o integrador</p>
                        </Col>
                    </Row>

                    <Row className="mt-2 pr-2 pl-2">
                        <Col>
                            <StyledNav
                                variant="tabs"
                                activeKey={selectedTabKey}
                                onSelect={setSelectedTabKey}
                            >
                                {tabsFiltered.map((currentTab: Tab) => (
                                    <Nav.Item key={currentTab.key}>
                                        <Nav.Link eventKey={currentTab.key}>
                                            {currentTab.label}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </StyledNav>
                        </Col>
                    </Row>

                    <Row className="mt-4 pl-2 pr-2">
                        <Col>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <div>{selectedTab?.element}</div>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}
        </BaseLayout>
    );
}
