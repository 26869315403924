import { useState, useEffect, ChangeEvent } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { StyledRow, StyledTitle } from './styles';
import { useConfiguration } from '../../../../../contexts/configurationContext';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import Branch from '../../../../../shared/interfaces/branch.interface';
import removeNonNumericChars from '../../../../../utils/removeNonNumericChars';
import BranchHttpService from '../../../../../services/http/branch-http';
import ProductHttpService from '../../../../../services/http/product.http';

interface FormData {
    name: string;
    erpCode: string;
    basePower: number;
    branches: BranchOptions[];
}

interface BranchOptions {
    label: string;
    value: number;
    id: number;
}

const ModuleEditor = (props: any) => {
    const [loading, setLoading] = useState(false);
    const {
        register,
        watch,
        setValue,
        reset,
        control,
        errors,
        setError,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            name: '',
            erpCode: '',
            basePower: 0,
            branches: [],
        },
    });
    const {
        setShowModuleEditorModal,
        setSelectedModule,
        selectedModule,
        setModules,
    } = useConfiguration();

    const formData = watch();

    const moduleRules = {
        branches: Yup.string().required('Campo obrigatório'),
    };

    async function saveModule() {
        setLoading(true);
        clearErrors();

        try {
            const schemaModules = Yup.object().shape(moduleRules);

            await schemaModules.validate(formData, {
                abortEarly: false,
            });
        } catch (error) {
            setError('branches', {
                type: 'campo',
                message: 'Campo Obrigatório',
            });
            setLoading(false);

            return;
        }

        try {
            const module: any = {
                id: selectedModule?.id,
                name: formData.name,
                erpCode: formData.erpCode,
                basePower: formData.basePower,
                branches: formData.branches,
            };

            await ProductHttpService.storeModule(module);

            toast.success('Módulo salvo.');

            const response = await ProductHttpService.getModules({});

            setModules(response.data.data);
        } catch (error) {
            toast.error('Erro ao salvar Módulo.');
        }

        setLoading(false);
        setShowModuleEditorModal(false);
        setSelectedModule({});
    }

    useEffect(() => {
        const selectedBranches = selectedModule?.branches?.map(
            (item: Branch) => ({
                label: item.name,
                value: item.id,
                id: item.id,
            }),
        );

        if (selectedModule.name) {
            setValue('name', selectedModule.name);
            setValue('erpCode', selectedModule.erpCode);
            setValue('basePower', selectedModule.basePower);
            setValue('branches', selectedBranches);
        } else {
            reset({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModule]);

    async function loadCompanyBranches() {
        const response = await BranchHttpService.readMany({});

        const companyBranches: BranchOptions[] = [];

        if (response?.data?.data) {
            response.data.data.map((branch: Branch) =>
                companyBranches.push({
                    label: branch.name,
                    id: branch.id,
                    value: branch.id,
                }),
            );
        }

        return companyBranches;
    }

    const checkForBranch = (id: number) => {
        const currentBranches = formData?.branches?.map(
            (branch: BranchOptions) => branch.id,
        );

        return !currentBranches?.includes(id);
    };

    const { data: companyBranches, isLoading } = useQuery(
        'companyBranches',
        loadCompanyBranches,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        },
    );

    const handleChangeErpCode = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = removeNonNumericChars(e.target.value);
    };

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Módulo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Filiais</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Group controlId="formBasicBranch">
                                <Controller
                                    className="form-control-nexen"
                                    as={Select}
                                    control={control}
                                    name="branches"
                                    options={
                                        !isLoading &&
                                        companyBranches?.filter(
                                            (branch: BranchOptions) =>
                                                checkForBranch(branch.id),
                                        )
                                    }
                                    isMulti
                                    styles={{
                                        control: tradeControl,
                                        option: optionsTheme,
                                    }}
                                    theme={theme}
                                    placeholder="Selecione..."
                                    defaultValue={[]}
                                    noOptionsMessage={() => 'Sem opções'}
                                />
                                {errors?.branches && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {
                                            (
                                                errors?.branches as unknown as FieldError
                                            )?.message
                                        }
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Nome</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="name"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                            {errors.name && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.name?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Código ERP</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="erpCode"
                                type="text"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                                onChange={handleChangeErpCode}
                            />
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Potência</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="basePower"
                                type="number"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowModuleEditorModal(false);
                                    setSelectedModule({});
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveModule();
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default ModuleEditor;
