import { Card, Col, Form, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import { SelectOption } from '../../../../Select';
import InverterBrand from '../../../../../shared/interfaces/inverterBrand.interface';
import isFriendlyHttpError from '../../../../../utils/isFriendlyHttpError';
import { InverterFilterProps } from '..';
import ProductHttpService from '../../../../../services/http/product.http';

interface InvertersFilterCardProps {
    control: Control<InverterFilterProps>;
}

export default function InvertersFilterCard({
    control,
}: InvertersFilterCardProps) {
    const activeOptions = [
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
    ];

    const invertersBrandQuery = useQuery({
        queryKey: ['invertersBrand'],
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        queryFn: async () => {
            const response = await ProductHttpService.getInverterBrands({});

            const brands: Array<SelectOption<string>> =
                response.data?.data?.map((brand: InverterBrand) => {
                    return {
                        label: `${brand.name}`,
                        value: brand.id.toString(),
                    };
                }) || [];

            return brands;
        },
        onError: (err) => {
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
            }

            toast.error('Ocorreu um erro ao carregar as marcas de inversores');
        },
    });

    return (
        <Card className="mt-2" style={{ width: '100%' }}>
            <Card.Body>
                <Card.Title>
                    <strong>Filtros</strong>
                </Card.Title>

                <Row>
                    <Col md={4}>
                        <Form.Group controlId="formBasicProduct">
                            <Form.Label>Marca</Form.Label>
                            <Controller
                                isClearable
                                className="form-control-nexen"
                                as={<Select />}
                                control={control}
                                name="filterBrand"
                                options={invertersBrandQuery?.data}
                                isDisabled={invertersBrandQuery.isLoading}
                                isMulti
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                defaultValue=""
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group controlId="formBasicAcessory">
                            <Form.Label>Ativo</Form.Label>
                            <Controller
                                isClearable
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="filterActive"
                                options={activeOptions}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                defaultValue={null}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
